import { ChevronDown } from 'assets/svg'
import DateUtils from 'core/utils/date-utils'
import React from 'react'
import { DVBCHistory, FrequencyLimitsType } from '../../redux/types'
import HistoryEntry from '../HistoryEntry'

type HistoryGroupEntryProps = {
  index: number
  historyDate: string
  accordionActiveIndex: number
  setAccordionActiveIndex: React.Dispatch<React.SetStateAction<number>>
  childAccordionActiveIndex: number
  setChildAccordionActiveIndex: React.Dispatch<React.SetStateAction<number>>
  handleActiveAccordion: (clickedAccordionIndex: number, currentActiveIndex: number, setCurrentActiveIndex: React.Dispatch<React.SetStateAction<number>>, resetChild: React.Dispatch<React.SetStateAction<number>>) => void
  frequencyLimits: FrequencyLimitsType
  historyGroup: Array<DVBCHistory>
}

export default function HistoryGroupEntry({
  index,
  historyDate,
  accordionActiveIndex,
  setAccordionActiveIndex,
  childAccordionActiveIndex,
  setChildAccordionActiveIndex,
  handleActiveAccordion,
  frequencyLimits,
  historyGroup,
}: HistoryGroupEntryProps) {
  return (
    <li
      className={`accordion-item ${accordionActiveIndex !== index ? 'accordion-item-collapsed' : ''}`}
      key={index}
    >
      <button
        className="accordion-heading"
        onClick={() => handleActiveAccordion(index, accordionActiveIndex, setAccordionActiveIndex, setChildAccordionActiveIndex)}
      >
        <span className="chevron">
          <span className="chevron-text">
            <b>
              {DateUtils.formatDate(historyDate, 'YYYY-MM-DD')}
            </b>
          </span>
          <span className="chevron-container accordion-chevron">
            <ChevronDown className="icon icon-svg" />
          </span>
        </span>
      </button>
      <div className="accordion-content">
        <ul>
          {historyGroup.map((historyEntry, childIndex) => (
            <HistoryEntry
            key={childIndex}
            index={childIndex}
            accordionActiveIndex={childAccordionActiveIndex}
            setAccordionActiveIndex={setChildAccordionActiveIndex}
            handleActiveAccordion={handleActiveAccordion}
            frequencyLimits={frequencyLimits}
            historyEntry={historyEntry}
            setChildAccordionActiveIndex={setChildAccordionActiveIndex}
            />
          ))}
        </ul>
      </div>
    </li>
  )
}

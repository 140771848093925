import { ApplicationState } from 'application/application-redux';
import { connect } from 'react-redux';
import ActionsComponent from './actions-component';
import {
  toggleAccordion,
  toggleAccordionOptions,
  resetActions,
  runCommands
} from '../redux/actions';
import { open as openModal, close as closeModal } from 'core/modal/redux/actions';
import { executeCommand } from 'core/utils/deviceUtils'

const mapStateToProps = (state: ApplicationState) => ({
  settings: state.devices.detail._settings,
  t: state.i18n.t,
  general: state.devices.detail.general,
});

export default connect(mapStateToProps, {
  toggleAccordion,
  toggleAccordionOptions,
  openModal,
  closeModal,
  resetActions,
  runCommands,
  executeCommand,
})(ActionsComponent);

import React from 'react'
import { SortedWifiEvents } from '../../redux/types'
import Graphic from './Graphic'
import CardComponent from 'core/card'
import { CardPaddings } from 'core/card/enum'
import { t } from 'i18next'
import NoRecords from 'core/no-records/no-records-container'

type BandwidthAndChannelProps = {
  events: SortedWifiEvents
}

export default function BandwidthAndChannel({ events }: BandwidthAndChannelProps) {
  return (
    <div className="tile is-parent is-vertical has-padding-sm">
      <div className="tile is-child">
        <CardComponent isFullHeight padding={CardPaddings.MEDIUM}>
          <div>
            <h2 className='subtitle'>{t('wifi_dashboards.bandwidth_channel_number')}</h2>
          </div>
          <div className="wifi-divider"/>
          {Object.keys(events).length ? <Graphic events={events}/> : <NoRecords  style={{ height: 'auto' }}/>}
        </CardComponent>
      </div>
    </div>
  )
}

import ErrorPage from "core/error-page"
import SpinnerComponent from "core/spinner/spinner-component"
import { useEffect, useState } from "react"
import ApplicationContainer from "./application-boostrapper"
import { envs, initializeEnvs } from "./envHandler"
import AuthService from 'core/auth'
import LocalSignIn from 'modules/login/local'

export default function ApplicationWrapper() {
  const [loading, setLoading] = useState(true)
  const [envsSet, setEnvsSet] = useState(false)

  useEffect(() => {
    const fetchEnvs = async () => {
      setLoading(true)

      const envsSuccessfullyInitialized = await initializeEnvs()

      setEnvsSet(envsSuccessfullyInitialized)
      setLoading(false)
    }

    fetchEnvs()
  }, [])

  if (loading) return <SpinnerComponent isActive />
  if (!loading && !envsSet) return <ErrorPage />

  if(!AuthService.getCurrentUser().token && envs.REACT_APP_IS_LOCAL) {
    return <LocalSignIn />
  }

  return <ApplicationContainer />
}

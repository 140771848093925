import React, { Component, Fragment } from 'react';
import { paginationDVR, paginationAccordionDvr } from 'core/pagination-optimized/redux/config';
import TableAccordionComponent from 'core/table-accordion/table-accordion-container';
import { TFunction } from 'i18next';
import mapper, { dvrColumns } from './redux/mapper';
import { DvrItem, DvrState, RecordingsItem } from './redux/types';
import { GeneralDeviceState } from '../general/redux/types';
import Topbar from '../topbar/topbar-container';
import MultipleSearchComponent from '../../../../../core/multiple-search/multiple-search-container';
import ItemsPerPageComponent from '../../../../../core/items-per-page/items-per-page-container';
import { ChevronDown } from 'assets/svg';
import './dvr-styles.scss';
import TimelineComponent from './timeline';
import NoRecordsComponent from 'core/no-records/no-records-container';
import { ExecuteCommandType } from 'core/utils/deviceUtils';

interface DvrComponentProps {
  dvr: DvrState;
  hhid: string;
  id: string;
  general: GeneralDeviceState;
  fetch: (params: { hhid: string, deviceId: string }) => void;
  deleteRecording: (item: RecordingsItem, id: string) => void;
  t: TFunction;
  deviceId: string;
  clearAccordionTable: (id: string) => void;
  getTimelineRecordings: (params: { hhid: string, deviceId: string }) => void;
  toggleTimeline: () => void;
  executeCommand: ExecuteCommandType
}

type DeleteRecordingCommandParams = {
  id: string
  general: GeneralDeviceState
  deleteRecording: (item: RecordingsItem, id: string) => void
  executeCommand: ExecuteCommandType
}
export default class DvrComponent extends Component<DvrComponentProps> {
  componentDidMount() {
    const { fetch, hhid, dvr, getTimelineRecordings, id } = this.props;

    if (dvr.items === undefined) {
      fetch({ hhid, deviceId: id });
    }

    if (dvr.timelineItems === undefined) {
      getTimelineRecordings({hhid, deviceId: id});
    }
  }

  deleteRecordingCommand(item: RecordingsItem, params: DeleteRecordingCommandParams) {
    const { deleteRecording, executeCommand, general, id } = params

    const commandName = 'modal.title.delete'
    executeCommand(general, commandName, () => deleteRecording(item, id))
  }

  render() {
    const { dvr, id, hhid, clearAccordionTable, fetch, t, toggleTimeline, deleteRecording, general, executeCommand } = this.props;
    const { deleteRecordingCommand } = this
    const hasTimelineItems =
      dvr.timelineItems && dvr.timelineItems.some((item: any) => item.status.length !== 0);

    return (
      <Fragment>
        <div className="columns width-full flex-wrap">
          <div className="column is-12">
            <Topbar id={id}></Topbar>
          </div>

          <div className="column is-relative is-12 has-padding-left-md has-padding-right-none has-padding-top-none">
            <button className="timeline-button" onClick={() => toggleTimeline()}>
              <ChevronDown
                className={`icon has-padding-xs icon-white icon-svg icon-xx-small
               ${dvr.isToShowTimeline ? 'icon-180' : ''}`}
              />
            </button>

            <div className="card is-relative has-margin-left-sm has-padding-bottom-none has-padding-left-none has-padding-right-none">
              <div className="columns has-margin-left-xxs has-margin-bottom-xxs has-padding-sm has-padding-right-lg">
                <div className="column is-vertical-center">
                  <h6 className="title is-6">{t('dvr.label_timeline_title')}</h6>
                </div>
                <div
                  className={`column is-narrow timeline-transition ${
                    dvr.isToShowTimeline && hasTimelineItems
                      ? 'timeline-expanded'
                      : 'timeline-collapsed'
                  }`}
                >
                </div>
              </div>
              <div
                className={`timeline-container-outer timeline-transition ${
                  dvr.isToShowTimeline ? 'timeline-expanded' : 'timeline-collapsed'
                }`}
              >
                {dvr.timelineItems && hasTimelineItems ? (
                  <TimelineComponent timelineData={dvr.timelineItems} />
                ) : (
                  <NoRecordsComponent />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="tile is-parent is-vertical has-margin-bottom-lg">
          <div className="table-optimized">
            <div className="columns has-padding-left-md has-padding-right-md has-padding-top-md has-margin-none">
              <div className="column">
                <h4 className="title is-size-4">{t('all_device_pages.menu_dvr')}</h4>
              </div>

              <ItemsPerPageComponent
                id={'accordion.dvr'}
                onChangeAction={() => {
                  clearAccordionTable('accordion.dvr');
                  fetch({hhid, deviceId: id});
                }}
              />
            </div>

            <div className="has-padding-left-md has-padding-right-md">
              <MultipleSearchComponent
                id={'accordion.dvr'}
                i18nKey={'dvr'}
                onMultipleSearch={() => {
                  clearAccordionTable('accordion.dvr');
                  fetch({hhid, deviceId: id});
                }}
                badgesOutside={true}
                removeCardPadding={true}
                persistentFilter={['recordingType']}
              />
            </div>

            <div className="is-parent is-vertical has-padding-right-md has-padding-left-md">
              <TableAccordionComponent
                tableId="dvr"
                tableKeys={['programId', 'program']}
                tableValues="recordings"
                tableColumns={dvrColumns()}
                tablePagination={paginationDVR}
                items={dvr?.items?.map((item: DvrItem) => mapper(item))}
                totalItems={dvr && dvr.totalElements}
                accordionPagination={paginationAccordionDvr}
                deleteTableItem={{
                  method: deleteRecordingCommand,
                  params: { id: { id, deleteRecording, general, executeCommand } }
                }}
                fetch={{
                  method: fetch,
                  allParams: { hhid, deviceId: id }
                }}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

import { ActionType, DVBCHistoryState, DVBC_HISTORY_SUCCESS, HistoryDataType } from "./types";

const initialState = {
  dvbcHistory: {}
} as {
  dvbcHistory: DVBCHistoryState
}

export default function (state = initialState, { type, payload }: ActionType) {
  switch (type) {
    case DVBC_HISTORY_SUCCESS:
      const historyData: HistoryDataType = {}

      payload.forEach((historyEntry) => {
        const currentDate = historyEntry.changeEventTs.split('T')[0]
        if(!historyData[currentDate]) {
          historyData[currentDate] = []
        }

        historyData[currentDate].push({ ...historyEntry})
      })

      return { ...state, dvbcHistory: historyData}

    default:
      return state
  }
}

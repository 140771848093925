import { connect } from "react-redux"
import RfTuningDvbcHistory from "./rf-tuning-dvbc-history-component"
import { fetchHistory } from './redux/actions'
import { ApplicationState } from "application/application-redux"

const mapStateToProps = (state: ApplicationState) => ({
  dvbc: state.devices.detail.general.shadow?.custom?.DVBC || [],
  dvbcHistory: state?.devices.detail.rfTuning.dvbc.dvbcHistory || {},
  frequencyLimits: state.devices.detail.rfTuning.status.frequencyLimits,
})

export default connect(mapStateToProps, {
  fetchHistory
})(RfTuningDvbcHistory)

import React from 'react'
import Icon from '@mdi/react'
import { mdiCircle } from '@mdi/js'
import { dangerColor, iconSize, successColor } from '../../constants'
import { t } from 'i18next'
import { getDate } from '../../utils'

type ClientSectionProps = {
  lastUpdateBy: string
  lastClientRealTimeDataUpdate: number
  connected: boolean
}

export default function ClientSection({ lastUpdateBy, lastClientRealTimeDataUpdate, connected }: ClientSectionProps) {
  return (
    <div className="section">
      <h3 className="section-title">{t('device_checks.labels_client')}</h3>
      <div className="columns">
        <div className="column is-6">{t('device_checks.labels_client_last_update_data')}:</div>
        <div className="column is-6">{lastUpdateBy}</div>
      </div>
      <div className="divider"/>
      <div className="columns">
        <div className="column is-6">{t('device_checks.labels_last_client_real_time_data_update')}:</div>
        <div className="column is-6">
          <div className="status-column">
            <Icon path={mdiCircle} size={iconSize} color={ connected ? successColor : dangerColor }/>
            <span>{getDate(lastClientRealTimeDataUpdate)}</span>
          </div>
        </div>
      </div>
      <div className="divider"/>
    </div>
  )
}

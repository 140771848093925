import { add, remove } from "core/spinner/redux/actions";
import { Dispatch } from "redux";
import authService from 'core/auth'
import apiClient from "core/api"
import { envs } from "application/envHandler";
import { FETCH_WIFI_ENTRIES, RESET_STATE, WifiDashboardsAPIResponse, WIFI_SUCCESS } from "./types";
import { addToaster } from "core/toaster/redux/actions";

function fetchSuccess(payload: WifiDashboardsAPIResponse) {
  return {
    type: WIFI_SUCCESS,
    payload,
  }
}

function resetState() {
  return {
    type: RESET_STATE
  }
}

export function onExit() {
  return (dispatch: Dispatch) => {
    dispatch(resetState())
  }
}

export function fetchWifiData(deviceId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(add(FETCH_WIFI_ENTRIES, {}))
    await authService.refreshToken()
    try {
      const { data } = await apiClient.get(`/wifi/device/${deviceId}`, {
        baseURL: envs.REACT_APP_API_URL
      })

      if (data) {
        dispatch(fetchSuccess(data))
      }
    } catch (e) {
      dispatch(
        addToaster({
          title: 'all_device_pages.menu_wifi_dashboards',
          message: 'error_messages.VIPOC_ERROR_069',
          type: 'danger'
        })
      )
    } finally {
      dispatch(remove(FETCH_WIFI_ENTRIES))
    }
  }
}
import { ANONYMIZER_SERVICE_CREATE_SUCCESS, ANONYMIZER_SERVICE_DELETE_SUCCESS, ANONYMIZER_SERVICE_LIST_FAILURE, ANONYMIZER_SERVICE_LIST_SUCCESS, ANONYMIZER_SERVICE_UPDATE_SUCCESS, initialServicesState } from "../../consts";
import { AnonymizerAction } from "../../types";

export default function (state = initialServicesState, { type, payload }: AnonymizerAction) {
  switch (type) {
    case ANONYMIZER_SERVICE_LIST_SUCCESS:          
      return payload || []

    case ANONYMIZER_SERVICE_LIST_FAILURE: 
      return []

    case ANONYMIZER_SERVICE_CREATE_SUCCESS:
      return [...state, payload]

    case ANONYMIZER_SERVICE_UPDATE_SUCCESS:
      const updatedServiceIndex = state.findIndex(service => service.id === payload.id)

      if (updatedServiceIndex >= 0) {
        state[updatedServiceIndex] = payload
      }

      return [ ...state ]

    case ANONYMIZER_SERVICE_DELETE_SUCCESS:
      const serviceToRemoveIndex = state.findIndex(service => service.id === payload)

      if (serviceToRemoveIndex >= 0) {
        state.splice(serviceToRemoveIndex, 1)
      }

      return [ ...state ]

    default: {
      return [ ...state ];
    }
  }
}

import { ApplicationState } from 'application/application-redux';
import { add, remove } from 'core/spinner/redux/actions';
import apiClient from 'core/api';
import { addToaster } from 'core/toaster/redux/actions';
import {
  DEVICE_DETAIL_LOGS_CHANGE_PAGE,
  DEVICE_DETAIL_LOGS_CLEAR_CACHE,
  DEVICE_DETAIL_LOGS_HISTORY,
  DEVICE_DETAIL_LOGS_HISTORY_FAILURE,
  DEVICE_DETAIL_LOGS_HISTORY_GENERATE_URL,
  DEVICE_DETAIL_LOGS_HISTORY_GENERATE_URL_SUCCESS,
  DEVICE_DETAIL_LOGS_HISTORY_SUCCESS,
  DEVICE_DETAIL_LOGS_RESET_URL,
} from './types';
import { PaginationConfig } from 'core/pagination-optimized/redux/types';
import { assignTableRows, clearTableCache } from 'core/table/redux/actions';
import { envs } from 'application/envHandler';

function success(data: any, start: number, end: number) {
  return {
    type: DEVICE_DETAIL_LOGS_HISTORY_SUCCESS,
    payload: {
      data,
      start,
      end,
    },
  };
}

function generateURLsuccess(data: any, items: number) {
  return {
    type: DEVICE_DETAIL_LOGS_HISTORY_GENERATE_URL_SUCCESS,
    payload: {
      data,
      items,
    },
  };
}

function failure(payload: any) {
  return {
    type: DEVICE_DETAIL_LOGS_HISTORY_FAILURE,
    payload,
  };
}

export function onChangeLogsHistoryPage(startItem: number, endItem: number) {
  return {
    type: DEVICE_DETAIL_LOGS_CHANGE_PAGE,
    payload: {
      startItem,
      endItem,
    },
  };
}

export function resetURL() {
  return {
    type: DEVICE_DETAIL_LOGS_RESET_URL,
  };
}

export function clearCacheLogsHistory() {
  return {
    type: DEVICE_DETAIL_LOGS_CLEAR_CACHE,
  };
}

function handleDatetime(startDateString: string, endDateString: string) {
  const startDateToQuery = new Date(startDateString).toISOString().replace('T', ' ').slice(0, -5)
  const endDateToQuery = new Date(endDateString).toISOString().replace('T', ' ').slice(0, -5)

  const returnObject = {
    startDate: startDateToQuery,
    endDate: endDateToQuery,
  }

  return returnObject
}

function editDate({ fileDate }: any) {
  if(fileDate.startDate) {
    return handleDatetime(fileDate.startDate, fileDate.endDate)
  }

  return handleDatetime(fileDate.start, fileDate.end)
}

function createId(items: any) {
  if (items) {
    for (let i = 0; i < items.length; i++) {
      items[i].rowId = '' + i;
    }
  }

  return items;
}

export function fetchLogsHistory(id?: string, filter?: PaginationConfig) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    dispatch(add(DEVICE_DETAIL_LOGS_HISTORY, {}));
    apiClient.get('/stb-logs', {
        baseURL: envs.REACT_APP_API_URL,
        params: {
          deviceId: id,
          ...editDate(filter)
        },
      })
      .then(
        (response) => {
          const pagination = getState().pagination.pagination.get('logs');
          const start = (pagination && pagination.pageSize * pagination.pageNumber) || 0;
          const end = (pagination && pagination.pageSize * (pagination.pageNumber + 1)) || 10;

          dispatch(resetURL());
          dispatch(clearTableCache());
          dispatch(remove(DEVICE_DETAIL_LOGS_HISTORY));
          dispatch(success(response.data, start, end));
          return dispatch(
            assignTableRows('logs', createId(response.data.records), 'rowId', 'filename')
          );
        },
        (rejection) => {
          dispatch(remove(DEVICE_DETAIL_LOGS_HISTORY));
          dispatch(
            addToaster({
              title: 'all_device_pages.menu_logs',
              message: 'error_messages.VIPOC_ERROR_055',
              type: 'danger',
            })
          );
          return dispatch(failure(rejection));
        }
      );
  };
}

export function generateURL(id?: string) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    const state = getState();
    const logsHistoryItems = state.table.items.get('logs');
    const data: any = [];

    if (logsHistoryItems) {
      logsHistoryItems.selectedRows.map((element: any) => {
        element.selected === true && data.push(element.field);
      });
    }

    dispatch(add(DEVICE_DETAIL_LOGS_HISTORY_GENERATE_URL, {}));
    apiClient
      .post(`${envs.REACT_APP_API_URL}/stb-logs/generateLink?deviceId=${id}`, data)
      .then(
        (response) => {
          dispatch(remove(DEVICE_DETAIL_LOGS_HISTORY_GENERATE_URL));

          const delay = (ms: number) => {
            return new Promise((resolve) => setTimeout(resolve, ms));
          };

          async function runWithDelay() {
            await delay(parseInt(response.data.timeToExpire));
            await dispatch(resetURL());
          }

          runWithDelay();

          return dispatch(generateURLsuccess(response.data, data.length));
        },
        (rejection) => {
          dispatch(remove(DEVICE_DETAIL_LOGS_HISTORY_GENERATE_URL));
          if (rejection.response.status === 400) {
            dispatch(
              addToaster({
                title: 'all_device_pages.menu_logs',
                message: 'error_messages.VIPOC_ERROR_058',
                type: 'danger',
              })
            );
          } else {
            dispatch(
              addToaster({
                title: 'all_device_pages.menu_logs',
                message: 'error_messages.VIPOC_ERROR_009',
                type: 'danger',
              })
            );
          }
          return dispatch(failure(rejection));
        }
      );
  };
}

import DateUtils from "core/utils/date-utils";
import { t } from "i18next";

export function getDate(date: number | string) {
  if(!date) return t('general.not_available')

  if(typeof date === 'number') {
    date = date.toString()
  }

  return DateUtils.formatDate(Number(date.padEnd(13, '0')))
}
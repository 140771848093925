import { SortedWifiEvents, WifiEvent } from "../../../redux/types"

export const labels = [
  '- 24h',
  '- 23h',
  '- 22h',
  '- 21h',
  '- 20h',
  '- 19h',
  '- 18h',
  '- 17h',
  '- 16h',
  '- 15h',
  '- 14h',
  '- 13h',
  '- 12h',
  '- 11h',
  '- 10h',
  '- 9h',
  '- 8h',
  '- 7h',
  '- 6h',
  '- 5h',
  '- 4h',
  '- 3h',
  '- 2h',
  '- 1h',
  '0',
]

function getChannelNumberAndBandwidth(event: WifiEvent | undefined) {
  if (event) {
    const channelNumber =  Number(event.channelNumber)
    return {
      channelNumber,
      bandwidth: channelNumber > 0 && channelNumber <= 14 ? 2.4 : 5
    }
  }

  return {
    channelNumber: 0,
    bandwidth: 0
  }
}

export function getData(events: SortedWifiEvents, bandwidthLegend: string, channelNumberLegend: string) {
  return labels.map(label => {
    const { bandwidth, channelNumber } = getChannelNumberAndBandwidth(events[label])
    return {
      name: label,
      [bandwidthLegend]: bandwidth,
      [channelNumberLegend]: channelNumber,
    }
  })
}

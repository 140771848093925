import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import DvrComponent from './dvr-component';
import { fetch, deleteRecording, getTimelineRecordings, toggleTimeline } from './redux/actions';
import { clearAccordionTable } from '../../../../../core/table-accordion/redux/actions';
import { executeCommand } from 'core/utils/deviceUtils'

function mapStateToProps(state: ApplicationState, ownProps: any) {
  return {
    dvr: state.devices.detail.dvr,
    general: state.devices.detail.general,
    hhid: ownProps.hhid,
    id: ownProps.id,
    t: state.i18n.t
  };
}

export default connect(mapStateToProps, {
  deleteRecording,
  fetch,
  clearAccordionTable,
  getTimelineRecordings,
  toggleTimeline,
  executeCommand,
})(DvrComponent);

import { RfTuningLimitValues } from "../../status/redux/types"

export const DVBC_HISTORY_SUCCESS = 'DVBC_HISTORY_SUCCESS'
export const DVBC_FETCH = 'DVBC_FETCH'

export type DVBC = Array<Array<string>>

export type DVBCHistory = {
  dvbc: DVBC,
  changeEventTs: string
  lastEventTs: string
  dsOpco: string
  count: string
  deviceId: string
  eventDt: string
}

export type ActionType = {
  type: string
  payload: Array<DVBCHistory>
}

export type DVBCRowType = {
  LF: string
  PWR: string
  BERPO: string
  LS: number
  TSID: string
}

export type FrequencyLimitsType = Array<RfTuningLimitValues>

export type HistoryDataType = {
  [k: string]: Array<DVBCHistory>
}

export type DVBCHistoryState = {
  [k: string]: Array<DVBCHistory>
}

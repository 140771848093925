import React from 'react'
import { DVBC, FrequencyLimitsType } from '../../redux/types'
import DVBCTable from '../DVBCTable'
import dvbcMapper from '../../redux/mapper'
import { t } from 'i18next'
import './index.scss'

type CurrentDVBCProps = {
  dvbc: DVBC
  frequencyLimits: FrequencyLimitsType
}

export default function CurrentDVBC({ dvbc, frequencyLimits }: CurrentDVBCProps) {
  return (
    <li className="accordion-item">
      <div className="accordion-heading current-dvbc-heading">
        <div className="chevron dvbc-header">
          <div className="chevron-text">
            <b>{t('rf_tuning.dvbc_history.current')}:</b>
          </div>
          <div className="dvbc-message">
            <h2 className="alert message">!</h2>
            <h2 className="message">{t('rf_tuning.dvbc_monitoring_not_real_time_data_message')}</h2></div>
        </div>
      </div>
      <div className="accordion-content">
        <DVBCTable dvbc={dvbcMapper(dvbc)} frequencyLimits={frequencyLimits}/>
      </div>
    </li>
  )
}

import React, { Component, Fragment } from 'react';
import { TFunction } from 'i18next';
import SettingsCardComponent from '../common/settings-card/settings-card-container';
import './configurations-styles.scss';
import { commandsMapper, parentalModeMapper } from '../redux/mapper';
import { CommandParameter } from '../redux/types';
import { GeneralDeviceState } from '../../general/redux/types'
import { ExecuteCommandType } from 'core/utils/deviceUtils';

interface ConfigurationsComponentProps {
  id: string;
  hhid?: string;
  t: TFunction;
  settings: any;
  componentId: any;
  clearFields: (componentId: string) => void;
  openModal: any;
  closeModal: any;
  runCommands: (id: string, command: CommandParameter) => void;
  changeParentalPin: (deviceId: string, parameter: any, hhid?: string) => void;
  setParentalPinError: () => void;
  general: GeneralDeviceState
  executeCommand: ExecuteCommandType
}

type Element = {
  name: string
  label: string
  type: string
  value: string
  currentValue: string
  previousValue: string
  available: Array<Available>
}

type Available = {
  label: string
  value?: string
  active: boolean
}

export default class ConfigurationsComponent extends Component<ConfigurationsComponentProps> {
  setCommandList = () => {
    const { settings, id, componentId, runCommands } = this.props;
    const component =
      settings &&
      settings.configurations &&
      settings.configurations.filter((item: any) => item.component === componentId);

    const changedFields = component[0].fields.filter(
      (field: any) => field.value != field.currentValue
    );

    const commands = commandsMapper(changedFields);
    return commands.map((command: any) => runCommands(id, command));
  };

  setParentalMode = () => {
    const { settings, id, componentId, hhid, runCommands, changeParentalPin, setParentalPinError } =
      this.props;
    const component =
      settings &&
      settings.configurations &&
      settings.configurations.filter((item: any) => item.component === componentId);

    const commands = parentalModeMapper(component);
    const parentalPin: any = commands.filter((item: any) => item.command === 'setParentalPin');
    const parentalMode = commands.filter((item: any) => item.command === 'setParentalMode');

    if (parentalPin.length > 0) {
      if (parentalPin[0].jsonParameters.pin.length < 4) {
        setParentalPinError();
      } else {
        changeParentalPin(id, parentalPin[0].jsonParameters.pin, hhid);
      }
    }

    return parentalMode.map((command: any) => runCommands(id, command));
  };

  public confirmUpdateExecution() {
    const { closeModal, openModal, componentId, general, executeCommand } = this.props;
    const commandTitle = 'modal.title.confirm_update'
    openModal({
      title: commandTitle,
      active: false,
      content: 'modal.content.confirm_update',
      execute: () => {
          closeModal()
          if(componentId === 'parental_mode') {
            return executeCommand(general, commandTitle, () => this.setParentalMode())
          }

          executeCommand(general, commandTitle, () => this.setCommandList())
      }
    });
  }

  onlyUniqueAvailable = (element: Element) => {
    const uniqueFields: Array<string> = []
    element.available = element.available.filter((field) => {
      if(!uniqueFields.includes(field.value || '')) {
        uniqueFields.push(field.value || '')
        return true
      }

      return false
    })

    return element
  }

  render() {
    const { t, componentId, settings, clearFields, general } = this.props;
    const component =
      settings &&
      settings.configurations &&
      settings.configurations.filter((item: any) => item.component === componentId);

    const hasNotChanged = component && !component[0].hasChanged
    
    return (
      <Fragment>
        <div className="columns flex-wrap">
          {component &&
            component[0].fields &&
            component[0].fields.map(
              (element: any, key: number) =>
                element &&
                Object.keys(element).length !== 0 && (
                  <div key={key} className={`column ${element.type === 'check' ? '' : 'is-4'}`}>
                    <SettingsCardComponent
                      i18nKey={'settings.label_'}
                      element={element.name === 'hdmiMode' ? this.onlyUniqueAvailable(element) : element}
                      componentId={component[0].component}
                      hasChanged={!hasNotChanged}
                    />
                  </div>
                )
            )}
        </div>
        <div className="columns flex-wrap is-justified-end is-vertical-center configurations-actions">
          <button
            className={`button-link dropdown-link has-padding-right-md ${
              hasNotChanged ? '' : 'button-action'
            }`}
            onClick={() => clearFields(componentId)}
            disabled={hasNotChanged}
          >
            {t(`general.clear`)}
          </button>

          <button
            className="button is-rounded is-larger is-primary"
            onClick={() => this.confirmUpdateExecution()}
            disabled={hasNotChanged}
          >
            {t('settings.button_apply')}
          </button>
        </div>
      </Fragment>
    );
  }
}

export const FETCH_WIFI_ENTRIES = 'FETCH_WIFI_ENTRIES'
export const WIFI_SUCCESS = 'WIFI_SUCCESS'
export const RESET_STATE = 'RESET_STATE'

export type WifiEvent = {
  channelNumber: any
  signal: string
  eventTs: string
  frequency: string
}

export type WifiDashboardsAPIResponse = {
  householdId: string
  insertTs: string
  wifiEvent: Array<WifiEvent>
  latestWifiEventTs: string
  opcoId: string
  deviceId: string
  customWfSigMin: string
  wifiScore: string
  customWfSigMax: string
}

export type SortedWifiEvents = {
  [k: string]: WifiEvent | undefined
}

export type WifiEventPayload = {
  type: string
  payload: WifiDashboardsAPIResponse
}

export type WifiState = {
  events: SortedWifiEvents
  minSignal: number | null
  maxSignal: number | null
  latestSignal: number | null
  score: number | null
}

import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import RfTuningStatusComponent from './rf-tuning-status-component';
import {
  fetchCommand,
  fetchDVBTypes,
  fetchHistory,
  fetchThresholds,
  onSelectFrequencyTab,
  onSelectGraphic,
  toggleAccordion,
  clearCache,
  clearCacheRFTuning,
  cancelCommand
} from './redux/actions';
import { executeCommand } from 'core/utils/deviceUtils'

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
  id: ownProps.id,
  results: state.devices.detail.rfTuning.status.results,
  activityLog: state.devices.detail.rfTuning.status.activityLog,
  currentFrequencyTab: state.devices.detail.rfTuning.status.currentFrequencyTab,
  frequencyListToShow: state.devices.detail.rfTuning.status.frequencyListToShow,
  resultToGraph: state.devices.detail.rfTuning.status.resultToGraph,
  frequencyLimits: state.devices.detail.rfTuning.status.frequencyLimits,
  currentGraphic: state.devices.detail.rfTuning.status.currentGraphic,
  resultHistoryToShow: state.devices.detail.rfTuning.status.resultHistoryToShow || [],
  allResults: state.devices.detail.rfTuning.status.allResults,
  waitingCommandExecution: state.devices.detail.rfTuning.status.waitingCommandExecution,
  t: state.i18n.t,
  general: state.devices.detail.general,
});

export default connect(mapStateToProps, {
  fetchCommand,
  fetchDVBTypes,
  fetchHistory,
  fetchThresholds,
  onSelectFrequencyTab,
  onSelectGraphic,
  toggleAccordion,
  clearCache,
  clearCacheRFTuning,
  fetch,
  cancelCommand,
  executeCommand,
})(RfTuningStatusComponent);

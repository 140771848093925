import React, { useEffect } from 'react'
import { GeneralDeviceState } from '../../general/redux/types'
import Topbar from '../../topbar/topbar-container'
import BandwidthAndChannelGraphic from './components/BandwidthAndChannelGraphic'
import WifiGraphic from './components/WifiGraphic'
import WifiOverview from './components/WifiOverview'
import { WifiState } from './redux/types'

type WifiDashboardsProps = {
  id: string
  general: GeneralDeviceState
  wifi: WifiState
  fetchWifiData: (id: string) => void
  onExit: () => void
}

export default function WifiDashboards({ id, fetchWifiData, general, wifi, onExit }: WifiDashboardsProps) {
  const { shadow } = general
  useEffect(() => {
    fetchWifiData(id)

    return onExit()
  }, [])

  return (
    <>
      <div className="columns width-full">
        <div className="column">
          <Topbar id={id} />
          {shadow && <WifiOverview shadow={shadow} />}
          <WifiGraphic events={wifi.events} latestSignal={wifi.latestSignal} maxSignal={wifi.maxSignal} minSignal={wifi.minSignal} score={wifi.score}/>
          <BandwidthAndChannelGraphic events={wifi.events}/>
        </div>
      </div>
    </>
  )
}

import React, { useState } from 'react'
import CardComponent from 'core/card/card-component'
import { CardPaddings } from 'core/card/enum'
import { t } from 'i18next'
import config from './config'
import ProfileLevel from './components/ProfileLevel'
import { CloseModalFunction, OpenModalFunction } from 'core/modal/redux/types'
import { GeneralDeviceState } from '../../../general/redux/types'
import { executeCommand, ExecuteCommandType } from 'core/utils/deviceUtils'

type JSONParameters = {
  window: number;
  appProfileLevel?: string
  osProfileLevel?: string
}

type CommandProps = {
  command: string
  key: string
  jsonParameters: JSONParameters
}

type RunCommandsFunction = (id: string, command: CommandProps) => void

type LogsProfileProps = {
  id: string
  openModal: OpenModalFunction
  closeModal: CloseModalFunction
  runCommands: RunCommandsFunction
  general: GeneralDeviceState
  executeCommand: ExecuteCommandType
}

function isDirty(appProfile: string, osProfile: string, window: number) {
  return (appProfile !== config.appProfileLevel.presets[0].value) || (osProfile !== config.osProfileLevel.presets[0].value) || (window !== config.window.presets[0].value)
}

function isValid(
  appProfile: string,
  appProfileCustomValue: string,
  osProfile: string,
  osProfileCustomValue: string,
  window: number,
) {
  const appProfileExists = !!appProfile
  const osProfileExists = !!osProfile

  let appProfileCondition = false
  let osProfileCondition = false

  let fieldsCondition = false

  const windowCondition = window > 0

  if (appProfileExists) {
    appProfileCondition = appProfile === 'custom' ? !!appProfileCustomValue : true
  }

  if (osProfileExists) {
    osProfileCondition = osProfile === 'custom' ? !!osProfileCustomValue : true
  }

  if (appProfileExists && osProfileExists) {
    fieldsCondition = appProfileCondition && osProfileCondition
  }

  if (appProfileExists && !osProfileExists) {
    fieldsCondition = appProfileCondition
  }

  if (osProfileExists && !appProfileExists) {
    fieldsCondition = osProfileCondition
  }

  return fieldsCondition && windowCondition
}

function resetStates(setAppProfile: React.Dispatch<React.SetStateAction<string>>, setAppProfileCustomValue: React.Dispatch<React.SetStateAction<string>>, setOsProfile: React.Dispatch<React.SetStateAction<string>>, setOsProfileCustomValue: React.Dispatch<React.SetStateAction<string>>, setWindow: React.Dispatch<React.SetStateAction<number>>) {
  setAppProfile(config.appProfileLevel.presets[0].value)
  setAppProfileCustomValue('')
  setOsProfile(config.osProfileLevel.presets[0].value)
  setOsProfileCustomValue('')
  setWindow(config.window.presets[0].value)
}

function turnOff(id: string, openModal: OpenModalFunction, closeModal: CloseModalFunction, runCommands: RunCommandsFunction, general: GeneralDeviceState, executeCommand: ExecuteCommandType) {
  const commandConfig = {
    command: 'loggingEnable',
    key: 'set_logs',
    jsonParameters: {
      appProfileLevel: config.appProfileLevel.presets[1].value,
      osProfileLevel: config.osProfileLevel.presets[1].value,
      window: 0,
    }
  }

  showModalAndExecCommand(id, openModal, closeModal, runCommands, commandConfig, general, executeCommand)
}

function turnOn(
  id: string,
  openModal: OpenModalFunction,
  closeModal: CloseModalFunction,
  runCommands: RunCommandsFunction,
  appProfile: string,
  appProfileCustomValue: string,
  osProfile: string,
  osProfileCustomValue: string,
  window: number,
  general: GeneralDeviceState,
  executeCommand: ExecuteCommandType
) {
  const jsonParameters: JSONParameters = { window }

  if(appProfile) {
    jsonParameters.appProfileLevel = appProfile === 'custom' ? appProfileCustomValue : appProfile
  }

  if(osProfile) {
    jsonParameters.osProfileLevel = osProfile === 'custom' ? osProfileCustomValue : osProfile
  }

  const commandConfig = {
    command: 'loggingEnable',
    key: 'set_logs',
    jsonParameters,
  }

  showModalAndExecCommand(id, openModal, closeModal, runCommands, commandConfig, general, executeCommand)
}

function showModalAndExecCommand(
  id: string,
  openModal: OpenModalFunction,
  closeModal: CloseModalFunction,
  runCommands: RunCommandsFunction,
  commandConfig: CommandProps,
  general: GeneralDeviceState,
  executeCommand: ExecuteCommandType
) {
  const commandName = 'modal.title.confirm_action'
  openModal({
    title:commandName,
    active: false,
    content: 'modal.content.confirm_action',
    execute: () => {
      closeModal()
      executeCommand(general, commandName, () => runCommands(id, commandConfig))
    }
  })
}

export default function LogsProfile({ id, openModal, closeModal, runCommands, general, executeCommand }: LogsProfileProps) {
  const [appProfile, setAppProfile] = useState(config.appProfileLevel.presets[0].value)
  const [appProfileCustomValue, setAppProfileCustomValue] = useState('')

  const [osProfile, setOsProfile] = useState(config.osProfileLevel.presets[0].value)
  const [osProfileCustomValue, setOsProfileCustomValue] = useState('')

  const [window, setWindow] = useState(config.window.presets[0].value)

  return (
    <div className="columns">
      <div className="column is-4">
        <CardComponent padding={CardPaddings.MEDIUM} isFullHeight>
          <div className="columns flex-wrap has-padding-xs has-margin-bottom-none">
            <div className="column is-12">
              <h6 className="text is-h6 is-highlighted">{t('settings.logs.app_profile')}</h6>
            </div>
            <div className="column is-12 has-padding-top-none has-padding-bottom-none">
              <h6 className="text is-h6 is-bold">{t('settings.current_value')}</h6>
            </div>
          </div>
          <ProfileLevel
            presets={config.appProfileLevel.presets}
            selectValue={appProfile}
            setSelectValue={setAppProfile}
            customValue={appProfileCustomValue}
            setCustomValue={setAppProfileCustomValue}
          />
        </CardComponent>
      </div>
      <div className="column is-4">
        <CardComponent padding={CardPaddings.MEDIUM} isFullHeight>
          <div className="columns flex-wrap has-padding-xs has-margin-bottom-none">
            <div className="column is-12">
              <h6 className="text is-h6 is-highlighted">{t('settings.logs.os_profile')}</h6>
            </div>
            <div className="column is-12 has-padding-top-none has-padding-bottom-none">
              <h6 className="text is-h6 is-bold">{t('settings.current_value')}</h6>
            </div>
          </div>
          <ProfileLevel
            presets={config.osProfileLevel.presets}
            selectValue={osProfile}
            setSelectValue={setOsProfile}
            customValue={osProfileCustomValue}
            setCustomValue={setOsProfileCustomValue}
          />
        </CardComponent>
      </div>
      <div className="column is-4">
        <CardComponent padding={CardPaddings.MEDIUM} isFullHeight>
          <div className="columns flex-wrap has-padding-xs has-margin-bottom-none">
            <div className="column is-12">
              <h6 className="text is-h6 is-highlighted">{t(`settings.logs_window`)}</h6>
            </div>
            <div className="column is-12 has-padding-top-none has-padding-bottom-none">
              <h6 className="text is-h6 is-bold">{t(`settings.column_value`)}</h6>
            </div>
          </div>
          <div className="has-padding-left-xs has-padding-right-xs">
            <div className="select is-fullwidth">
              <select
                name="window"
                value={window}
                onChange={(e) => setWindow(parseInt(e.target.value))}
              >
                {config.window.presets.map(
                  (item, key: number) => (
                    <option key={key} value={item.value}>
                      {t(item.label)}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>
        </CardComponent>
        <div className="columns flex-wrap is-justified-end is-vertical-center configurations-actions">
          <button
            className={`button-link dropdown-link has-padding-right-md ${isDirty(appProfile, osProfile, window) ? 'button-action' : ''}`}
            disabled={!isDirty(appProfile, osProfile, window)}
            onClick={() => resetStates(setAppProfile, setAppProfileCustomValue, setOsProfile, setOsProfileCustomValue, setWindow)}
          >
            {t(`general.clear`)}
          </button>
          <button
            className="button is-rounded is-larger is-dark"
            onClick={() => turnOff(id, openModal, closeModal, runCommands, general, executeCommand)}
            disabled={isDirty(appProfile, osProfile, window)}
          >
            {t('settings.logs.button_turnoff')}
          </button>
          <button
            className="button is-rounded is-larger is-primary"
            onClick={() => {
              turnOn(id, openModal, closeModal, runCommands, appProfile, appProfileCustomValue, osProfile, osProfileCustomValue, window, general, executeCommand)
            }}
            disabled={!isValid(appProfile, appProfileCustomValue, osProfile, osProfileCustomValue, window)}
          >
            {t('settings.logs.button_turnon')}
          </button>
        </div>
      </div>
    </div>
  )
}

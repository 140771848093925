import React, { useEffect } from 'react'
import HistoryList from './components/HistoryList'
import { DVBC, DVBCHistory, DVBCHistoryState, FrequencyLimitsType } from './redux/types'

type RfTuningDvbcHistoryProps = {
  fetchHistory: (id: string) => void,
  dvbcHistory: DVBCHistoryState
  dvbc: DVBC
  deviceId: string
  frequencyLimits: FrequencyLimitsType
}

export default function RfTuningDvbcHistory({ dvbcHistory, fetchHistory, deviceId, frequencyLimits, dvbc }: RfTuningDvbcHistoryProps) {
  useEffect(() => {
    fetchHistory(deviceId)
  }, [])
  
  return (
    <div>
      <HistoryList currentDVBC={dvbc} dvbcHistory={dvbcHistory} frequencyLimits={frequencyLimits}/>
    </div>
  )
}

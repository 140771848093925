import { t } from 'i18next'
import { DeviceShadow } from 'modules/operational/device/detail/general/redux/types'
import React from 'react'
import CardComponent from 'core/card'
import { CardPaddings } from 'core/card/enum'

type WifiOverviewProps = {
  shadow: DeviceShadow
}

export default function WifiOverview({ shadow }: WifiOverviewProps) {
  const { reported, custom } = shadow
  return (
    <div className="tile is-parent is-vertical has-padding-sm">
      <div className="tile is-child">
        <CardComponent isFullHeight={true} padding={CardPaddings.MEDIUM}>
          <div className="columns has-margin-bottom-none has-padding-md">
            <div className="column ">
              <h5 className="text is-h5">{t('advanced_search.column_connection')}</h5>
            </div>
          </div>
          <div className="columns flex-wrap has-padding-right-md has-padding-left-md has-padding-bottom-md">
            <div className="column">
              <p className="has-text-weight-bold text is-h6 is-bold">
                {t('overview.label_status')}
              </p>
              <p className="text is-h6 has-margin-top-xxs">
                {reported.config.nType !== 'undefined' && reported.config.nType.toLowerCase() === 'wifi'
                  ? t('overview.overview_wifi_status_on')
                  : t('overview.overview_wifi_status_off')}
              </p>
            </div>
            <div className="column">
              <p className="has-text-weight-bold text is-h6 is-bold">
                {t('overview.label_ssid')}
              </p>
              <p className="text is-h6 has-margin-top-xxs">
                {custom.SSID !== 'undefined'
                  ? custom.SSID
                  : t('general.not_available')}
              </p>
            </div>
            <div className="column">
              <p className="has-text-weight-bold text is-h6 is-bold">
                {t('overview.label_signal_strength')}
              </p>
              <p className="text is-h6 has-margin-top-xxs">
                {custom.WFSig !== 'undefined'
                  ? custom.WFSig
                  : t('general.not_available')}
              </p>
            </div>
            <div className="column">
              <p className="has-text-weight-bold text is-h6 is-bold">
                {t('overview.label_channel_number')}
              </p>
              <p className="text is-h6 has-margin-top-xxs">
                {custom.WFChn !== 'undefined'
                  ? custom.WFChn
                  : t('general.not_available')}
              </p>
            </div>
            <div className="column">
              <p className="has-text-weight-bold text is-h6 is-bold">
                {t('overview.label_frequency')}
              </p>
              <p className="text is-h6 has-margin-top-xxs">
                {custom.WFFrq !== 'undefined'
                  ? custom.WFFrq
                  : t('general.not_available')}
              </p>
            </div>
            <div className="column">
              <p className="has-text-weight-bold text is-h6 is-bold">
                {t('overview.label_wifi_encryption_mode')}
              </p>
              <p className="text is-h6 has-margin-top-xxs">
                {custom.WFENC !== 'Not available'
                  ? custom.WFENC
                  : t('general.not_available')}
              </p>
            </div>
          </div>
        </CardComponent>
      </div>
    </div>
  )
}

import { connect } from 'react-redux';
import {
  startScan,
  changeScanType,
  changeCustomTypeValue,
  addCustomFrequency,
  deleteCustomFrequency,
  clearListCustomFrequencies,
  changeDropdown,
  toggleScanAccordion
} from './redux/actions';
import RfTuningScanComponent from './rf-tuning-scan-component';
import { cancelCommand, fetchHistory } from '../status/redux/actions';
import { executeCommand } from 'core/utils/deviceUtils'

const mapStateToProps = (state: any, ownProps: any) => ({
  id: ownProps.id,
  results: state.devices.detail.rfTuning.scan.results,
  lastResultDate: state.devices.detail.rfTuning.scan.lastResultDate,
  form: state.devices.detail.rfTuning.scan.form,
  waitingCommandExecution: state.devices.detail.rfTuning.scan.waitingCommandExecution,
  activityLog: state.devices.detail.rfTuning.scan.activityLog,
  scan: state.devices.detail.rfTuning.scan,
  t: state.i18n.t,
  frequencyLimits: state.devices.detail.rfTuning.status.frequencyLimits,
  general: state.devices.detail.general,
});

export default connect(mapStateToProps, {
  startScan,
  changeScanType,
  changeCustomTypeValue,
  cancelCommand,
  addCustomFrequency,
  deleteCustomFrequency,
  clearListCustomFrequencies,
  changeDropdown,
  fetchHistory,
  toggleScanAccordion,
  executeCommand,
})(RfTuningScanComponent);

import { combineReducers } from 'redux';
import rfTuningScanReducer from '../scan/redux';
import rfTuningStatusReducer from '../status/redux';
import mainReducer from './reducer';
import dvbcHistoryReducer from '../dvbc/redux'

export default combineReducers({
  scan: rfTuningScanReducer,
  main: mainReducer,
  status: rfTuningStatusReducer,
  dvbc: dvbcHistoryReducer,
});

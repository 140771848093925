
import { addToaster } from "core/toaster/redux/actions"
import { t } from "i18next"
import { GeneralDeviceState } from "modules/operational/device/detail/general/redux/types"
import { Dispatch } from "redux"

export type ExecuteCommandType = (general: GeneralDeviceState, commandName: string, commandCallback: Function) => void

export function executeCommand(...[general, commandName, commandCallback]: Parameters<ExecuteCommandType>) {
  return function(dispatch: Dispatch) {
    if(general?.shadow?.reported?.status?.connected === 'true') {
      return commandCallback()
    }

    return dispatch(
      addToaster({
        title: t(commandName),
        message: t('error_messages.VIPOC_ERROR_068'),
        type: 'danger'
      })
    )
  }
}

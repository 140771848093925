import { ChevronDown } from 'assets/svg'
import DateUtils from 'core/utils/date-utils'
import { t } from 'i18next'
import React from 'react'
import { DVBCHistory, FrequencyLimitsType } from '../../redux/types'
import DVBCTable from '../DVBCTable'
import NoRecordsComponent from 'core/no-records/no-records-container'
import dvbcMapper from '../../redux/mapper'

type HistoryEntryProps = {
  index: number
  accordionActiveIndex: number
  setAccordionActiveIndex: React.Dispatch<React.SetStateAction<number>>
  setChildAccordionActiveIndex: React.Dispatch<React.SetStateAction<number>>
  handleActiveAccordion: (clickedAccordionIndex: number, currentActiveIndex: number, setCurrentActiveIndex: React.Dispatch<React.SetStateAction<number>>, setChildAccordionActiveIndex: React.Dispatch<React.SetStateAction<number>>) => void
  frequencyLimits: FrequencyLimitsType
  historyEntry: DVBCHistory
}

export default function HistoryEntry({
  index,
  accordionActiveIndex,
  setAccordionActiveIndex,
  setChildAccordionActiveIndex,
  handleActiveAccordion,
  frequencyLimits,
  historyEntry,
}: HistoryEntryProps) {
  return (
    <li
      className={`accordion-item ${accordionActiveIndex !== index ? 'accordion-item-collapsed' : ''}`}
      key={index}
    >
      <button
        className="accordion-heading"
        onClick={() => handleActiveAccordion(index, accordionActiveIndex, setAccordionActiveIndex, setChildAccordionActiveIndex)}
      >
        <span className="chevron">
          <span className="chevron-text">
            <b>
              {t('rf_tuning.dvbc_history.last_available_data')}:&nbsp;
              {DateUtils.formatDate(historyEntry.lastEventTs)}&nbsp;|&nbsp;
            </b>
            <b>
              {t('rf_tuning.dvbc_history.last_data_modified')}:&nbsp;
              {DateUtils.formatDate(historyEntry.changeEventTs)}&nbsp;|&nbsp;
            </b>
            <b>
              {t('rf_tuning.dvbc_history.count')}:&nbsp;
              {historyEntry.count}
            </b>
          </span>
          <span className="chevron-container accordion-chevron">
            <ChevronDown className="icon icon-svg" />
          </span>
        </span>
      </button>
      <div className="accordion-content">
        {historyEntry.dvbc ? <DVBCTable dvbc={dvbcMapper(historyEntry.dvbc)} frequencyLimits={frequencyLimits}/> : <NoRecordsComponent />}
      </div>
    </li>
  )
}

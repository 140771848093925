import { connect } from "react-redux"
import LogsProfile from "./profile-component"
import { open as openModal, close as closeModal } from 'core/modal/redux/actions'
import { runCommands } from '../../redux/actions'
import { executeCommand } from 'core/utils/deviceUtils'
import { ApplicationState } from "application/application-redux"

const mapStateToProps = (state: ApplicationState) => ({
  general: state.devices.detail.general,
})

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  runCommands,
  executeCommand
})(LogsProfile)

import React, { PureComponent, Fragment } from 'react';
import RfTuningFullFrequencyHistoryComponent from './rf-tuning-status-component-history';
import { PaginationConfig } from '../../../../../../core/pagination-optimized/redux/types';
import { RfTuningStatusResult, RfTuningHistoryResult } from './redux/types';
import GraphicComponent from '../../graphic/graphic-component';
import T from 'core/i18n/t';
import { TFunction } from 'i18next';
import RfTuningTableComponent from './rf-tuning-table-component';
import TaskPanelComponent from 'core/job-panel';
import { ExecuteCommandType } from 'core/utils/deviceUtils';
import { GeneralDeviceState } from '../../general/redux/types';

export interface RfTuningStatusComponentProps {
  currentFrequencyTab: string;
  currentGraphic: string;
  hhid: string;
  id: string;
  frequencyListToShow: Array<String>;
  results: Array<RfTuningStatusResult>;
  resultHistoryToShow: Array<RfTuningHistoryResult>;
  frequencyLimits: any;
  resultToGraph: any;
  fetchCommand: (id: string) => void;
  fetchDVBTypes: (id: string) => void;
  fetchHistory: (id: string, assetName: string, filter?: PaginationConfig) => void;
  fetchThresholds: (id: string) => void;
  onSelectFrequencyTab: (frequency: string) => void;
  onSelectGraphic: (graphic: any) => void;
  toggleAccordion: (index: number) => void;
  t: TFunction;
  clearCache: (id: string) => void;
  waitingCommandExecution: boolean;
  cancelCommand: (traceId: string, deviceId: string, t: TFunction) => void;
  activityLog: any;
  general: GeneralDeviceState
  executeCommand: ExecuteCommandType
}

export default class RfTuningStatusComponent extends PureComponent<RfTuningStatusComponentProps> {
  componentDidMount() {
    if (Object.keys(this.props.frequencyLimits).length === 0) {
      this.props.fetchThresholds(this.props.id);
    }
    if (this.props.frequencyListToShow.length === 0) {
      this.props.fetchDVBTypes(this.props.id);
    }
  }

  onScan() {
    if (!this.props.currentFrequencyTab) {
      return;
    }
    const frequencyType = this.props.currentFrequencyTab.split('_');
    const frequencyTypeString = frequencyType[0] + '_' + frequencyType[1] + '_' + frequencyType[2];
    this.props.onSelectFrequencyTab(frequencyTypeString);
    this.props.fetchCommand(this.props.id);
  }

  public render() {
    const { general, executeCommand } = this.props
    return (
      <Fragment>
        <div className="columns container is-fluid has-margin-left-none has-margin-right-none is-vcentered">
          <div className="column is-narrow control is-paddingless">
            <button
              className="button is-primary is-rounded is-larger"
              onClick={() => executeCommand(general, 'rf_tuning.button_scan', () => this.onScan())}
              disabled={this.props.waitingCommandExecution}
            >
              <T>rf_tuning.button_scan</T>
            </button>
          </div>
          {this.props.currentFrequencyTab && this.props.currentFrequencyTab.includes('graphic') && (
            <div className="column control is-paddingless">
              <div className="select is-right">
                <select
                  value={this.props.currentGraphic}
                  onChange={(event: any) => this.props.onSelectGraphic(event.target.value)}
                >
                  <option value="rf_tuning.full_frequency_pwr">
                    {this.props.t('rf_tuning.full_frequency_pwr')}
                  </option>
                  <option value="rf_tuning.full_frequency_berpo">
                    {this.props.t('rf_tuning.full_frequency_berpo')}
                  </option>
                </select>
              </div>
            </div>
          )}
          <div
            className={`column control is-paddingless${
              this.props.currentFrequencyTab && this.props.currentFrequencyTab.includes('graphic')
                ? ' is-narrow'
                : ''
            }`}
          >
            <div className="select is-right">
              <select
                disabled={this.props.waitingCommandExecution}
                value={this.props.currentFrequencyTab}
                onChange={(event: any) => this.props.onSelectFrequencyTab(event.target.value)}
              >
                {this.props.frequencyListToShow.map((type: any, key: number) => (
                  <option key={key} value={type}>
                    {this.props.t(type)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {this.props.activityLog && this.props.activityLog.status !== 'SUCCEEDED' && (
          <ul className="panel has-margin-bottom-md">
            <TaskPanelComponent
              item={this.props.activityLog}
              showProgressBar
              cancel={this.props.cancelCommand}
              t={this.props.t}
            />
          </ul>
        )}

        {this.props.currentFrequencyTab && this.props.currentFrequencyTab.includes('history') && (
          <RfTuningFullFrequencyHistoryComponent
            fetchHistory={this.props.fetchHistory}
            toggleAccordion={this.props.toggleAccordion}
            hhid={this.props.hhid}
            id={this.props.id}
            resultHistoryToShow={this.props.resultHistoryToShow}
            frequencyLimits={this.props.frequencyLimits}
            historyType={'runChannelsStats'}
          />
        )}

        {this.props.currentFrequencyTab && this.props.currentFrequencyTab.includes('graphic') && (
          <GraphicComponent
            data={this.props.resultToGraph}
            currentGraphic={this.props.currentGraphic}
          />
        )}

        {this.props.currentFrequencyTab &&
          !this.props.currentFrequencyTab.includes('graphic') &&
          !this.props.currentFrequencyTab.includes('history') && (
            <RfTuningTableComponent
              frequencyHistories={this.props.results}
              frequencyLimits={this.props.frequencyLimits}
            />
          )}
      </Fragment>
    );
  }
}

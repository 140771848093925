import { ApplicationState } from "application/application-redux";
import { connect } from "react-redux";
import WifiDashboardsComponent from "./wifi-dashboards-components";
import { fetchWifiData, onExit } from './redux/actions'

const mapStateToProps = (state: ApplicationState) => ({
  general: state.devices.detail.general,
  wifi: state.devices.detail.wifi,
})

export default connect(mapStateToProps, {
  fetchWifiData,
  onExit,
})(WifiDashboardsComponent)

import { connect } from 'react-redux';
import SpeedTestComponent from './speed-test-component';
import { fetchSpeedTest, fetch, cancelCommand } from './redux/actions';
import { ApplicationState } from 'application/application-redux';
import { executeCommand } from 'core/utils/deviceUtils'

function mapStateToProps(state: ApplicationState, ownProps: any) {
  return {
    speedTest: state.devices.detail.speedTest,
    general: state.devices.detail.general,
    search: state.search,
    id: ownProps.id,
    t: state.i18n.t
  };
}

export default connect(mapStateToProps, {
  onSearch: fetch,
  onStartSpeedTest: fetchSpeedTest,
  cancelCommand, 
  executeCommand,
})(SpeedTestComponent);

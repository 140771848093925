import { t } from 'i18next'
import React from 'react'

type TitleProps = {
  minSignal: number | null
  maxSignal: number | null
  latestSignal: number | null
  score: number | null
}

function getdataLabel(data: number | null) {  
  return (!data && data !== 0) ? t('general.not_available') : `${data}%`
}

export default function Title({ minSignal, maxSignal, latestSignal, score }: TitleProps) {
  return (
    <div>
      <h2 className='subtitle'>{t('wifi_dashboards.signal_strength')}</h2>
      <div className="wifi-display-flex">
        <div className="wifi-item">
          <div className="wifi-item-title">{t('wifi_dashboards.latest_signal_strength')}</div>
          <div className="wifi-item-value">{getdataLabel(latestSignal)}</div>
        </div>
        <div className="wifi-item">
          <div className="wifi-item-title">{t('wifi_dashboards.maximum_signal')}</div>
          <div className="wifi-item-value">{getdataLabel(maxSignal)}</div>
        </div>
        <div className="wifi-item">
          <div className="wifi-item-title">{t('wifi_dashboards.minimum_signal')}</div>
          <div className="wifi-item-value">{getdataLabel(minSignal)}</div>
        </div>
        <div className="wifi-item">
          <div className="wifi-item-title">{t('wifi_dashboards.score')}</div>
          <div className="wifi-item-value">{getdataLabel(score)}</div>
        </div>
      </div>
    </div>
  )
}

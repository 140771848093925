import moment from 'moment';
import { SearchFilter, SearchItem, ComparatorType } from './types';

const numericComparators: Array<ComparatorType> = [
  { name: 'general.comparator_equal', value: 'EQ' },
  { name: 'general.comparator_less_than', value: 'LT' },
  { name: 'general.comparator_greater_than', value: 'GT' },
  { name: 'general.comparator_less_or_equal', value: 'LE' },
  { name: 'general.comparator_greater_or_equal', value: 'GE' }
];

const provDevicesFilterTypes: Array<SearchItem> = [
  { name: 'Device Id', value: 'udid', type: 'string', label: 'account.provisioned_devices.column_device_id' },
  { name: 'Device type', value: 'deviceType', type: 'list', listValues: ['Managed', 'Unmanaged'], label: 'account.provisioned_devices.column_device_type' },
  {
    name: 'Device family',
    value: 'deviceFamily',
    type: 'list',
    listValues: [
      'Smartphone',
      'STB',
      'ConnectedTV',
      'Tablet',
      'PC/MAC',
      'Game consoles',
      'SmartTV',
      'Streaming devices'
    ],
    label: 'account.provisioned_devices.column_device_family',
  },
  { name: 'Last Managed', value: 'lastManaged', type: 'date', label: 'account.provisioned_devices.column_last_managed' }
];

export const searchFilterProvDevices: SearchFilter = {
  filterTypes: provDevicesFilterTypes,
  currentFilter: { type: provDevicesFilterTypes[0], value: '' }
};

const subscriptionsFilterTypes: Array<SearchItem> = [
  { name: 'Subscription Id', value: 'subscriptionId', type: 'string', label: 'account.subscriptions.column_subscription_id' },
  { name: 'Transaction Id', value: 'transactionId', type: 'string', label: 'account.subscriptions.column_transaction_id' },
  { name: 'Name', value: 'moduleName', type: 'string', label: 'account.subscriptions.column_module_name' },
  { name: 'Purchase date', value: 'purchaseDate', type: 'date', label: 'account.subscriptions.column_purchase_date' },
  { name: 'Renewal date', value: 'renewalDate', type: 'date', label: 'account.subscriptions.column_renewal_date' },
  { name: 'Price', value: 'price', type: 'string', label: 'account.subscriptions.column_price' }
];

export const searchFilterSubscriptions: SearchFilter = {
  filterTypes: subscriptionsFilterTypes,
  currentFilter: { type: subscriptionsFilterTypes[0], value: '' }
};

const dvrFilterTypes: Array<SearchItem> = [
  { name: 'Asset Id', value: 'asset', type: 'string' },
  { name: 'Event Id', value: 'programId', type: 'string' },
  { name: 'Program', value: 'program', type: 'string' },
  { name: 'Channel', value: 'channel', type: 'string' },
  { name: 'Channel number', value: 'channelNumber', type: 'string' },
  { name: 'Start time', value: 'startTime', type: 'date' },
  { name: 'End time', value: 'endTime', type: 'date' },
  { name: 'Actual start time', value: 'actualStartTime', type: 'date' },
  { name: 'Actual end time', value: 'actualEndTime', type: 'date' },
  { name: 'Source', value: 'source', type: 'string' },
  { name: 'Last update', value: 'lastEvent', type: 'date' },
  { name: 'Status', value: 'status', type: 'string' }
];

export const searchFilterDvr: SearchFilter = {
  filterTypes: dvrFilterTypes,
  currentFilter: { type: dvrFilterTypes[0], value: '' }
};

const vodFilterTypes: Array<SearchItem> = [
  { name: 'Asset Id', value: 'assetId', type: 'string', label: 'vod.column_asset_id'},
  { name: 'Transaction Id', value: 'transactionId', type: 'string', label: 'vod.column_transaction_id' },
  { name: 'Asset title', value: 'assetTitle', type: 'string', label: 'vod.column_asset_title' },
  { name: 'Asset type', value: 'assetType', type: 'list', listValues: ['Free', 'Pay', 'Promotion'], label: 'vod.column_asset_type' },
  // { name: 'Purchase date', value: 'purchaseDate', type: 'date' },
  // { name: 'Expiry date', value: 'expiryDate', type: 'date' },
  { name: 'Duration', value: 'duration', type: 'string', label: 'vod.column_duration' },
  { name: 'Watched', value: 'watched', type: 'boolean', label: 'vod.column_watched' }
];

export const searchFilterVod: SearchFilter = {
  filterTypes: vodFilterTypes,
  currentFilter: { type: vodFilterTypes[0], value: '' },
  moreFilters: {
    days: 0,
    state: true
  }
};

const activityLogFilterTypes: Array<{ name: string; value: string; type: string }> = [
  { name: 'Status', value: 'status', type: 'string' },
  { name: 'Event name', value: 'eventName', type: 'string' },
  { name: 'Event type', value: 'eventType', type: 'string' },
  { name: 'Last Managed', value: 'lastManaged', type: 'date' }
];

export const searchFilterActivityLog: SearchFilter = {
  filterTypes: activityLogFilterTypes,
  currentFilter: { type: activityLogFilterTypes[0], value: '' }
};

const speedTestFilterTypes: Array<SearchItem> = [
  { name: 'Download', value: 'downloadBitrateAVG', type: 'string', label: 'speed_test.column_download_bitrate_avg' },
  { name: 'Ping', value: 'pingTimeMS', type: 'string', label: 'speed_test.column_ping_time_ms' },
  { name: 'Date', value: 'date', type: 'date', label: 'speed_test.column_date' }
];

export const searchFilterSpeedTest: SearchFilter = {
  filterTypes: speedTestFilterTypes,
  currentFilter: { type: speedTestFilterTypes[0], value: '' },
  numberFilters: numericComparators,
  currentComparator: 'EQ',
  showComparators: true,
};

const channelsFilterTypes: Array<SearchItem> = [
  { name: 'Media Id', value: 'mediaId', type: 'string' },
  { name: 'Channel number', value: 'channelNumber', type: 'range' },
  { name: 'Channel name', value: 'channelName', type: 'string' },
  { name: 'Device Type', value: 'deviceType', type: 'string' },
  { name: 'Level', value: 'level', type: 'list', listValues: ['', 'local', 'regional'] }
];

export const searchFilterChannels: SearchFilter = {
  filterTypes: channelsFilterTypes,
  currentFilter: { type: channelsFilterTypes[0], value: '' }
};

const RegionsFilterTypes: Array<{
  name: string;
  value: string;
  type: string;
}> = [
  { name: 'Region Id', value: 'regionId', type: 'string' },
  { name: 'Region label', value: 'regionLabel', type: 'string' },
  { name: 'Parent region Id', value: 'parentRegionId', type: 'string' }
];

export const searchFilterRegions: SearchFilter = {
  filterTypes: RegionsFilterTypes,
  currentFilter: { type: RegionsFilterTypes[0], value: '' }
};

const NodesFilterTypes: Array<{
  name: string;
  value: string;
  type: string;
}> = [
  { name: 'Node Id', value: 'nodeId', type: 'string' },
  { name: 'Node label', value: 'nodeLabel', type: 'string' },
  { name: 'Child region Id', value: 'childRegionId', type: 'string' }
];

export const searchFilterNodes: SearchFilter = {
  filterTypes: NodesFilterTypes,
  currentFilter: { type: NodesFilterTypes[0], value: '' }
};

export const logsHistoryFilterTypes: Array<SearchItem> = [
  { name: 'File date', value: 'fileDate', type: 'date', label: 'logs.column_file_date' }
];

export const searchFilterLogsHistory: SearchFilter = {
  filterTypes: logsHistoryFilterTypes,
  currentFilter: {
    type: logsHistoryFilterTypes[0],
    value: {
      startDate: moment(new Date()),
      endDate: moment(new Date()),
    }
  }
};

export const regionalizationFilterTypes: Array<{
  name: string;
  value: string;
  type: string;
}> = [
  { name: 'Media Id', value: 'mediaId', type: 'string' },
  { name: 'Channel Name', value: 'channelName', type: 'string' },
  { name: 'Regional LCN', value: 'regionalLCN', type: 'string' }
];

export const searchFilterRegionalization: SearchFilter = {
  filterTypes: regionalizationFilterTypes,
  currentFilter: { type: regionalizationFilterTypes[0], value: '' }
};

export const regionalizationRemainingFilterTypes: Array<{
  name: string;
  value: string;
  type: string;
}> = [
  { name: 'Media Id', value: 'mediaId', type: 'string' },
  { name: 'Channel Name', value: 'channelName', type: 'string' }
];

export const searchFilterRegionalizationRemaining : SearchFilter = {
  filterTypes: regionalizationRemainingFilterTypes,
  currentFilter: { type: regionalizationRemainingFilterTypes[0], value: '' }
};

export const columnsToCompare: any = {
  speed_test: ['downloadBitrateAVG', 'pingTimeMS']
};

export const searchDevicesFilterTypes: Array<{
  name: string;
  value: string;
  type: string;
}> = [
  { name: 'UDID', value: 'udid', type: 'string' },
  { name: 'HHID', value: 'hhid', type: 'string' },
  { name: 'KDSN', value: 'kdsn', type: 'string' }
];
export const requestsFilterTypes: Array<SearchItem> = [
  { name: 'Status', value: 'status', type: 'string' },

];

export const searchFilterRequests: SearchFilter = {
  filterTypes: requestsFilterTypes,
  currentFilter: { type: requestsFilterTypes[0], value: '' }

};

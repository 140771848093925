import { t } from 'i18next'
import React, { useState } from 'react'
import { PresetString } from '../../config'
import './index.scss'

type ProfileLevelProps = {
  selectValue: string
  setSelectValue: React.Dispatch<React.SetStateAction<string>>
  customValue: string
  setCustomValue: React.Dispatch<React.SetStateAction<string>>
  presets: Array<PresetString>
}

function handleSelectOnChange(
  e: React.ChangeEvent<HTMLSelectElement>,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  setCustomValue: React.Dispatch<React.SetStateAction<string>>
) {
  setValue(e.target.value)
  setCustomValue('')
}

export default function ProfileLevel({ presets, selectValue, setSelectValue, setCustomValue, customValue }: ProfileLevelProps) {
  return (
    <>
      <div className="flex-wrap" style={{ display: 'flex', marginBottom: 0}}>
        <div className="select is-fullwidth">
          <select value={selectValue} onChange={e => handleSelectOnChange(e, setSelectValue, setCustomValue)}> 
            {presets.map(option => <option key={option.value} value={option.value}>{t(option.label)}</option>)}
            <option value="custom">{t('settings.logs_custom')}</option>
          </select>
        </div>
        <div className="input-container-profile-level">
          <h6 className="text is-h6 is-bold">{t('settings.logs_custom_path')}</h6>
          <input
            className="input"
            disabled={selectValue !== 'custom'}
            onChange={e => setCustomValue(e.target.value)}
            value={customValue}
          />
        </div>
      </div>
    </>
  )
}

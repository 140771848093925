import mapper from "./mapper";
import { RESET_STATE, WifiEventPayload, WifiState, WIFI_SUCCESS } from "./types";

const initialState: WifiState = {
  events: {},
  minSignal: null,
  maxSignal: null,
  latestSignal: null,
  score: null,
}

export default function (state = initialState, { type, payload }: WifiEventPayload) {
  switch (type) {
    case WIFI_SUCCESS:
      return { ...state, ...mapper(payload) }
  
    case RESET_STATE:
      return { ...initialState }

    default:
      return { ...state };
  }
}

import React, { Component } from 'react';
import { NoRecords } from 'assets/svg';
import { TFunction } from 'i18next';

interface NoRecordsProps {
  t: TFunction;
  title?: String;
  subtitle?: String;
  borderTop?: boolean;
  style?: React.CSSProperties;
}

export default class NoRecordsComponent extends Component<NoRecordsProps> {
  render() {
    const { t, title, subtitle, borderTop, style } = this.props;

    return (
      <div
        className={`card flex-column is-vertical-center is-justified-center no-data has-text-centered has-padding-lg
        has-margin-top-sm ${borderTop && 'has-border-top'}`}
        style={style}
      >
        <NoRecords className="has-margin-bottom-lg" />
        <h5 className="text is-h5 is-bold has-margin-bottom-md">
          {title || t('general.label_no_results')}
        </h5>
        {subtitle}
      </div>
    );
  }
}

/// <reference path="index.d.ts" />

import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import wrapAxios from 'zipkin-instrumentation-axiosjs';

import { Tracer, ExplicitContext, ConsoleRecorder } from 'zipkin';
import AuthService from '../auth/auth-service';
import { envs } from 'application/envHandler';

const ctxImpl = new ExplicitContext();
const recorder = new ConsoleRecorder();
const localServiceName = 'vipoc-fe-react';
const remoteServiceName = 'vipoc-fe-nodejs';

const tracer = new Tracer({ ctxImpl, recorder, localServiceName });

export class ApiClient {
  private client: AxiosInstance = wrapAxios(Axios.create(), { tracer, remoteServiceName });

  public initialize(accessTokenKey: string): void {
    this.client.interceptors.request.use(request => {
      const idToken = localStorage.getItem(accessTokenKey);
      const currentUser = AuthService.getCurrentUser();

      if (
        request.method !== 'get' &&
        (request.url?.includes('command') ||
          request.url?.includes('remove') ||
          request.url?.includes('change-parental-pin') ||
          request.url?.includes('activity-log')) &&
        !currentUser.groups.some(
          (it) =>
            it.prefix === 'module' &&
            it.moduleName === 'devices' &&
            it.role === 'su' &&
            it.opco === AuthService.getCurrentOpco()
        )
      ) {
        return Promise.reject(new Error('error_messages.permission_denied'));
      }
      if (!request.headers.ignoreCommonHeaders) {
        request.headers.token = idToken;
        request.headers.opco = AuthService.getCurrentOpco();
      }

      return request;
    });

    this.client.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response && error.response.status === 401) {
          AuthService.logoutAndRedirect()
        }

        return Promise.reject(error);
      }
    );
  }

  public get(url: string, config?: AxiosRequestConfig) {
    return this.client.get(url, config);
  }

  public post(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.client.post(url, data, config);
  }

  public put(url: string, data: any, config?: AxiosRequestConfig) {
    return this.client.put(url, data, config);
  }

  public delete(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.client.delete(url, {data, ...config});
  }
}

export default new ApiClient();

import React from 'react'
import CardComponent from 'core/card'
import { CardPaddings } from 'core/card/enum'
import { SortedWifiEvents } from '../../redux/types'
import './index.scss'
import Title from './Title'
import Graphic from './Graphic'
import NoRecords from 'core/no-records/no-records-container'

type WifiGraphicProps = {
  events: SortedWifiEvents
  minSignal: number | null
  maxSignal: number | null
  latestSignal: number | null
  score: number | null
}

export default function WifiGraphic({ minSignal, maxSignal, latestSignal, events, score }: WifiGraphicProps) {
  return (
    <div className="tile is-parent is-vertical has-padding-sm">
      <div className="tile is-child">
        <CardComponent isFullHeight padding={CardPaddings.MEDIUM}>
          <div>
            <Title minSignal={minSignal} maxSignal={maxSignal} latestSignal={latestSignal} score={score}/>
          </div>
          <div className="wifi-divider"/>
          {Object.keys(events).length ? <Graphic events={events}/> : <NoRecords  style={{ height: 'auto' }}/>}
        </CardComponent>
      </div>
    </div>
  )
}

import { DeviceShadow } from './types';

function RCULabel(rcm: string, os: string, notAvailableLabel: string) {
  if(!rcm){
    return { label: notAvailableLabel, isRCMConnected: false }
  }

  if (os !== 'android') {
    return { label: rcm, isRCMConnected: true }
  }

  const regexp = /\b\w+(?:\s+\w+)*\s*\(/ // Matches all words + ' ('
  const androidLabel = rcm.replace(regexp, '').replace(')', '')
  return { label: androidLabel, isRCMConnected: rcm.split(' ')[0] === 'Yes' }
}

export default (data: any, notAvailableLabel: string): DeviceShadow => {
  const response = data.responseJson;
  const reported = response ? response.deviceShadow.reported : {};
  const reportedPrevDeviceShadow = response && response.prevDeviceShadow ? response.prevDeviceShadow.reported : {};

  const rcu = RCULabel(reported?.config?.RCM, reported?.software?.os, notAvailableLabel)
  
  return {
    reported: {
      account: {
        HHID:
          reported?.account?.HHID
            ? reported.account.HHID
            : notAvailableLabel,
        cType:
          reported?.account?.cType
            ? reported.account.cType
            : notAvailableLabel,
        regID:
          reported?.account?.regID
            ? reported.account.regID
            : notAvailableLabel
      },
      checksum: reported?.checksum ? reported.checksum : notAvailableLabel,
      config: {
        GDPRCsV:
          reported?.config?.GDPRCsV
            ? reported.config.GDPRCsV
            : notAvailableLabel,
        HDMIAM:
          reported?.config?.HDMIAM
            ? reported.config.HDMIAM
            : notAvailableLabel,
        HDMIM:
          reported?.config?.HDMIM
            ? reported.config.HDMIM
            : notAvailableLabel,
        HDMIR:
          reported?.config?.HDMIR
            ? reported.config.HDMIR
            : notAvailableLabel,
        RCM: rcu.label,
        TVCtrl:
          reported?.config?.TVCtrl
            ? reported.config.TVCtrl
            : notAvailableLabel,
        UILg:
          reported?.config?.UILg
            ? reported.config.UILg
            : notAvailableLabel,
        actStByT:
          reported?.config?.actStByT
            ? reported.config.actStByT
            : notAvailableLabel,
        conDev:
          reported?.config?.conDev
            ? reported.config.conDev
            : notAvailableLabel,
        hearImpSu:
          reported?.config?.hearImpSu
            ? reported.config.hearImpSu
            : notAvailableLabel,
        nType:
          reported?.config?.nType
            ? reported.config.nType
            : notAvailableLabel,
        pAuLg:
          reported?.config?.pAuLg
            ? reported.config.pAuLg
            : notAvailableLabel,
        pRatg:
          reported?.config?.pRatg
            ? reported.config.pRatg
            : notAvailableLabel,
        pSuLg:
          reported?.config?.pSuLg
            ? reported.config.pSuLg
            : notAvailableLabel,
        pSvM:
          reported?.config?.pSvM
            ? reported.config.pSvM
            : notAvailableLabel,
        sAuLg:
          reported?.config?.sAuLg
            ? reported.config.sAuLg
            : notAvailableLabel,
        sSuLg:
          reported?.config?.sSuLg
            ? reported.config.sSuLg
            : notAvailableLabel,
        standBy:
          reported?.config?.standBy
            ? reported.config.standBy
            : notAvailableLabel,
        subs:
          reported?.config?.subs
            ? reported.config.subs
            : notAvailableLabel,
        unSuCh:
          reported?.config?.unSuCh
            ? reported.config.unSuCh
            : notAvailableLabel,
        usageID:
          reported?.config?.usageID
          ? reported.config.usageID
          : notAvailableLabel,
        visImpAu:
          reported?.config?.visImpAu
            ? reported.config.visImpAu
            : notAvailableLabel
      },
      hardware: {
        SN:
          reported?.hardware?.SN
            ? reported.hardware.SN
            : notAvailableLabel,
        bootT:
          reported?.hardware?.bootT
            ? reported.hardware.bootT
            : notAvailableLabel,
        brn:
          reported?.hardware?.brn
            ? reported.hardware.brn
            : notAvailableLabel,
        mod:
          reported?.hardware?.mod
            ? reported.hardware.mod
            : notAvailableLabel
      },
      security: {
      cas: {
        ID:
          reported?.security?.cas?.ID
            ? reported.security.cas.ID
            : notAvailableLabel,
        name:
          reported?.security?.cas?.name
            ? reported.security.cas.name
            : notAvailableLabel,
        ver:
          reported?.security?.cas?.ver
            ? reported.security.cas.ver
            : notAvailableLabel
      },
      drm:
        reported?.security?.drm
          ? reported.security.drm
          : notAvailableLabel
    },
      software: {
        RCVer:
          reported?.software?.RCVer
            ? reported.software.RCVer
            : notAvailableLabel,
        UI:
          reported?.software?.UI
            ? reported.software.UI
            : notAvailableLabel,
        ver:
          reported?.software?.ver
            ? reported.software.ver
            : notAvailableLabel,
        os: reported?.software?.os || notAvailableLabel
      },
      status: {
        connected:
          reported?.status?.connected
            ? reported.status.connected
            : notAvailableLabel,
        connectedFor:
          reported?.status?.connectedFor
            ? reported.status.connectedFor
            : notAvailableLabel,
        lastConnectionDate:
          reported?.status?.lastConnectionDate
            ? reported.status.lastConnectionDate
            : notAvailableLabel
      }
    },
    udid: response && response.udid ? response.udid : notAvailableLabel,
    externalID: response && response.externalID ? response.externalID : notAvailableLabel,
    custom: {
      HDMIC:
        response && response.Custom && response.Custom.HDMIC
          ? response.Custom.HDMIC
          : notAvailableLabel,
      HDMID: {
        manufacturerName:
          response && response.Custom && response.Custom.HDMID.manufacturerName
            ? response.Custom.HDMID.manufacturerName
            : notAvailableLabel,
        hdmiModes:
          response && response.Custom && response.Custom.HDMID.hdmiModes
            ? response.Custom.HDMID.hdmiModes
            : notAvailableLabel
      },
      CPUTp:
        response && response.Custom && response.Custom.CPUTp
          ? response.Custom.CPUTp
          : notAvailableLabel,
      CAL:
        response && response.Custom && response.Custom.CAL
          ? response.Custom.CAL
          : notAvailableLabel,
      MUTE:
        response && response.Custom && response.Custom.MUTE
          ? response.Custom.MUTE
          : notAvailableLabel,
      PIN:
        response && response.Custom && response.Custom.PIN
          ? response.Custom.PIN
          : notAvailableLabel,
      CPULV:
        response && response.Custom && response.Custom.CPULV
          ? response.Custom.CPULV + '%'
          : notAvailableLabel,
      CGMAC:
        response && response.Custom && response.Custom.CGMAC
          ? response.Custom.CGMAC
          : notAvailableLabel,
      REGIDBE:
        response && response.Custom && response.Custom.REGIDBE
          ? response.Custom.REGIDBE
          : notAvailableLabel,
      REGSET:
        response && response.Custom && response.Custom.REGSET
          ? response.Custom.REGSET
          : notAvailableLabel,
      WanIP:
        response && response.Custom && response.Custom.WanIP
          ? response.Custom.WanIP
          : notAvailableLabel,
      HDCP:
        response && response.Custom && response.Custom.HDCP
          ? response.Custom.HDCP
          : notAvailableLabel,
      HDDST:
        response && response.Custom && response.Custom.HDDST
          ? response.Custom.HDDST
          : notAvailableLabel,
      HDDTP:
        response && response.Custom && response.Custom.HDDTP
          ? response.Custom.HDDTP
          : notAvailableLabel,
      HDDPNr:
        response && response.Custom && response.Custom.HDDPNr
          ? response.Custom.HDDPNr
          : notAvailableLabel,
      HDDSN:
        response && response.Custom && response.Custom.HDDSN
          ? response.Custom.HDDSN
          : notAvailableLabel,
      HDDTS:
        response && response.Custom && response.Custom.HDDTS
          ? response.Custom.HDDTS
          : notAvailableLabel,
      HDDUS:
        response && response.Custom && response.Custom.HDDUS
          ? response.Custom.HDDUS
          : notAvailableLabel,
      HWV:
        response && response.Custom && response.Custom.HWV
          ? response.Custom.HWV
          : notAvailableLabel,
      IPMsk:
        response && response.Custom && response.Custom.IPMsk
          ? response.Custom.IPMsk
          : notAvailableLabel,
      MACEt:
        response && response.Custom && response.Custom.MACEt
          ? response.Custom.MACEt
          : notAvailableLabel,
      NetCg:
        response && response.Custom && response.Custom.NetCg
          ? response.Custom.NetCg
          : notAvailableLabel,
      NType:
        response && response.Custom && response.Custom.NType
          ? response.Custom.NType
          : notAvailableLabel,
      PrDNS:
        response && response.Custom && response.Custom.PrDNS
          ? response.Custom.PrDNS
          : notAvailableLabel,
      PrvIP:
        response && response.Custom && response.Custom.PrvIP
          ? response.Custom.PrvIP
          : notAvailableLabel,
      RCURN:
        response && response.Custom && response.Custom.RCURN
          ? response.Custom.RCURN
          : notAvailableLabel,
      ScDNS:
        response && response.Custom && response.Custom.ScDNS
          ? response.Custom.ScDNS
          : notAvailableLabel,
      SwUpd:
        response && response.Custom && response.Custom.SwUpd
          ? response.Custom.SwUpd
          : notAvailableLabel,
      WFChn:
        response && response.Custom && response.Custom.WFChn
          ? response.Custom.WFChn
          : notAvailableLabel,
      WFFrq:
        response && response.Custom && response.Custom.WFFrq
          ? response.Custom.WFFrq
          : notAvailableLabel,
      WFMAC:
        response && response.Custom && response.Custom.WFMAC
          ? response.Custom.WFMAC
          : notAvailableLabel,
      WFSig:
        response && response.Custom && response.Custom.WFSig
          ? response.Custom.WFSig
          : notAvailableLabel,
      HDDSMART:
        response && response.Custom && response.Custom.HDDSMART
          ? response.Custom.HDDSMART
          : notAvailableLabel,

      BRNME:
        response && response.Custom && response.Custom.BRNME
          ? response.Custom.BRNME
          : notAvailableLabel,
      SSID:
        response && response.Custom && response.Custom.SSID
          ? response.Custom.SSID
          : notAvailableLabel,
      WFENC:
        response && response.Custom && response.Custom.WFENC
          ? response.Custom.WFENC
          : notAvailableLabel,
      NGRS:
        response && response.Custom && (response.Custom.NGRS || response.Custom.NGRS === '')
          ? response.Custom.NGRS
          : notAvailableLabel,
      DVBC: response?.Custom?.DVBC || [],
    },
    pCustom: {
      pHDCP:
        response && response.pCustom && response.pCustom.pHDCP
          ? response.pCustom.pHDCP
          : notAvailableLabel,
      pHDMIR:
        response && response.pCustom && response.pCustom.pHDMIR
          ? response.pCustom.pHDMIR
          : notAvailableLabel,
      pCal:
        response && response.pCustom && response.pCustom.pCAL
          ? response.pCustom.pCAL
          : notAvailableLabel
    },
    userConversion: {
      channelName:
        response && response.UserConversion && response.UserConversion.channelName
          ? response.UserConversion.channelName
          : notAvailableLabel,
      pChannelName:
        response && response.UserConversion && response.UserConversion.pChannelName
          ? response.UserConversion.pChannelName
          : notAvailableLabel
    },
    prevDeviceShadow: {
      config: {
        HDMIM:
          reportedPrevDeviceShadow &&
          reportedPrevDeviceShadow.config &&
          reportedPrevDeviceShadow.config.HDMIM
            ? reportedPrevDeviceShadow.config.HDMIM
            : notAvailableLabel
      }
    },
    rcglifecycleevents: {
      sourceIp:
        response && response.RCGLifecycleEvents && response.RCGLifecycleEvents.sourceIp
          ? response.RCGLifecycleEvents.sourceIp
          : notAvailableLabel
    },
    userID: response && response.userID ? response.userID : notAvailableLabel,
    householdID: response && response.householdID ? response.householdID : notAvailableLabel,
    lastUpdateBy: (!response.lastUpdateBy || typeof response.lastUpdateBy === 'object') ? notAvailableLabel :  response.lastUpdateBy,
    lastTechnicalDataEventDate: (!response.lastTechnicalDataEventDate || typeof response.lastTechnicalDataEventDate === 'object') ? notAvailableLabel : response.lastTechnicalDataEventDate,
    lastUpdateDateUserConversion: response.lastUpdateDateUserConversion || 0,
    lastUpdateDateDeviceMonitoring: response.lastUpdateDateDeviceMonitoring || 0,
    lastUpdateDateRcgProvisioning: response.lastUpdateDateRcgProvisioning || 0,
    lastUpdateDateRcgShadowUpdate: response.lastUpdateDateRcgShadowUpdate || 0,
    domainExernalID:
      response && response.domainExternalID && typeof response.domainExternalID !== 'object'
        ? response.domainExternalID
        : notAvailableLabel,
    auxProperties: {
      isRCMConnected: rcu.isRCMConnected
    }
  };
};

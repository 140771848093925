import React, { Component, Fragment } from 'react';
import { TFunction } from 'i18next';
import { AssetValue, CommandParameter, SettingsField, SettingsState } from '../redux/types';
import '../settings-styles.scss';
import { ChevronDown } from 'assets/svg';
import CardComponent from 'core/card';
import { CardPaddings } from 'core/card/enum';
import { OverlayModalState } from 'core/modal/redux/types';
import { actionsMapper } from '../redux/mapper';
import { ExecuteCommandType } from 'core/utils/deviceUtils';
import { GeneralDeviceState } from '../../general/redux/types';
interface ActionsComponentProps {
  id: string;
  settings: SettingsState;
  t: TFunction;
  toggleAccordion: (index: number) => void;
  toggleAccordionOptions: (option: string) => void;
  openModal: (options: OverlayModalState) => void;
  closeModal: () => void;
  resetActions: () => void;
  runCommands: (id: string, command: CommandParameter) => void;
  general: GeneralDeviceState
  executeCommand: ExecuteCommandType
}

export default class ActionsComponent extends Component<ActionsComponentProps> {
  confirmCommandActionExecution() {
    const { openModal, closeModal, executeCommand, general } = this.props;
    const commandName = 'modal.title.confirm_action'
    openModal({
      title: commandName,
      active: false,
      content: 'modal.content.confirm_action',
      execute: () => {
        closeModal();
        executeCommand(general, commandName, () => this.runActions())
      }
    });
  }

  runActions = () => {
    const { settings, runCommands, id } = this.props;
    const selectedActions =
      settings.actions && settings.actions.fields.filter((item: SettingsField) => item.selected);

    return runCommands(id, actionsMapper(selectedActions));
  };

  render() {
    const { settings, t, toggleAccordion, toggleAccordionOptions, resetActions } = this.props;
    const isDisabled =
      settings &&
      settings.actions &&
      settings.actions.fields &&
      settings.actions.fields.some(
        (item: SettingsField) =>
          item.available && item.available.some((option: AssetValue) => option.selected)
      );    
    return (
      <Fragment>
        {settings &&
          settings.actions &&
          settings.actions.fields &&
          settings.actions.fields.map((item: SettingsField, index: number) => {
            return (
              <div className="accordion-item" key={index}>
                <CardComponent padding={CardPaddings.MEDIUM}>
                  <div>
                    <button
                      className="accordion-heading has-padding-xs"
                      onClick={() => toggleAccordion(index)}
                    >
                      <div className="columns">
                        <div className="column">
                          <h5>{t(`settings.label_${item.name}`)}</h5>
                        </div>
                        <div
                          className={`column is-narrow ${
                            item.selected ? 'actions-expanded' : 'actions-collapsed'
                          }`}
                        >
                          <ChevronDown
                            className={`icon-svg icon-xxx-small has-padding-top-xs icon-red`}
                          />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    className={`has-border-top has-padding-top-md has-margin-top-xs ${
                      !item.selected && 'is-hidden'
                    }`}
                  >
                    <div className="columns flex-wrap has-padding-top-sm has-margin-bottom-none">
                      {item &&
                        item.available &&
                        item.available.map((element: AssetValue, index: number) => {
                          return element && item && item.type === 'check' ? (
                            <div className={`column is-12 has-padding-top-none`} key={index}>
                              <div className="has-checkbox checkbox">
                                <input
                                  className="is-small"
                                  type="checkbox"
                                  name={t(element.label)}
                                  checked={element.selected}
                                  onChange={() => toggleAccordionOptions(element.label)}
                                />
                                <label>{t(`settings.label_${element.label}`)}</label>
                              </div>
                            </div>
                          ) : (
                            <div
                              className={`has-padding-bottom-md has-padding-left-md has-padding-right-md`}
                              key={index}
                            >
                              <label
                                className="radio"
                                onClick={() => toggleAccordionOptions(element.label)}
                              >
                                <input
                                  className="is-checkradio is-small"
                                  type="radio"
                                  checked={element.selected}
                                  readOnly
                                />
                                <label>{t(`${element.label}`)}</label>
                              </label>
                            </div>
                          );
                        })}
                    </div>

                    <div className="columns flex-wrap is-justified-end is-vertical-center has-padding-bottom-md has-padding-right-sm">
                      <button
                        className={`button-link dropdown-link has-padding-right-md ${
                          !isDisabled ? '' : 'button-action'
                        }`}
                        disabled={!isDisabled}
                        onClick={() => resetActions()}
                      >
                        {t(`general.clear`)}
                      </button>

                      <button
                        className="button is-rounded is-larger is-primary"
                        onClick={() => this.confirmCommandActionExecution()}
                        disabled={!isDisabled}
                      >
                        {t('settings.button_run')}
                      </button>
                    </div>
                  </div>
                </CardComponent>
              </div>
            );
          })}
      </Fragment>
    );
  }
}

import React, { useState } from 'react'
import { DVBC, DVBCHistoryState, FrequencyLimitsType } from '../../redux/types'
import NoRecordsComponent from 'core/no-records/no-records-container'
import HistoryGroupEntry from '../HistoryGroupEntry'
import CurrentDVBC from '../CurrentDVBC'
import './index.scss'

type HistoryListProps = {
  dvbcHistory: DVBCHistoryState
  frequencyLimits: FrequencyLimitsType
  currentDVBC: DVBC
}

function handleActiveAccordion(
  clickedAccordionIndex: number,
  currentActiveIndex: number,
  setCurrentActiveIndex: React.Dispatch<React.SetStateAction<number>>,
  resetChildIndex: React.Dispatch<React.SetStateAction<number>>,
) {
  resetChildIndex(-1)

  if(currentActiveIndex === clickedAccordionIndex) {
    return setCurrentActiveIndex(-1)
  }

  setCurrentActiveIndex(clickedAccordionIndex)
}

export default function HistoryList({ dvbcHistory, frequencyLimits, currentDVBC }: HistoryListProps) {
  const [accordionActiveIndex, setAccordionActiveIndex] = useState(-1)
  const [childAccordionActiveIndex, setChildAccordionActiveIndex] = useState(-1)

  if((!dvbcHistory || Object.keys(dvbcHistory).length === 0) && !currentDVBC.length) {
    return (
      <div className="has-text-centered">
        <NoRecordsComponent />
      </div>
    )
  }

  return (
    <ul className="accordion">
      {currentDVBC.length > 0 && (
        <CurrentDVBC dvbc={currentDVBC} frequencyLimits={frequencyLimits}/>
      )}
      {Object.keys(dvbcHistory).map((historyDateEntry, index) => (
        dvbcHistory[historyDateEntry].length > 0 ?
          <HistoryGroupEntry
            key={index}
            index={index}
            historyDate={historyDateEntry}
            accordionActiveIndex={accordionActiveIndex}
            setAccordionActiveIndex={setAccordionActiveIndex}
            childAccordionActiveIndex={childAccordionActiveIndex}
            setChildAccordionActiveIndex={setChildAccordionActiveIndex}
            handleActiveAccordion={handleActiveAccordion}
            frequencyLimits={frequencyLimits}
            historyGroup={dvbcHistory[historyDateEntry]}
          />
          : null
        ))}
    </ul>
  )
}

import { connect } from 'react-redux';
import { ApplicationState } from 'application/application-redux';
import {
  fetchChannelScan as startScan,
  fetch,
  toggleAccordion,
  cancelCommand
} from './redux/actions';
import ChannelComponent from './channels-component';
import { executeCommand } from 'core/utils/deviceUtils'

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
  hhid: ownProps.hhid,
  id: ownProps.id,
  results: state.devices.detail.channels,
  t: state.i18n.t,
  general: state.devices.detail.general,
});

export default connect(mapStateToProps, { startScan, fetch, toggleAccordion, cancelCommand, executeCommand })(
  ChannelComponent
);

import { t } from 'i18next'
import React, { useEffect, useRef, useState } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import { SortedWifiEvents } from '../../../redux/types'
import { getData } from './setup'

type GraphicProps = {
  events: SortedWifiEvents
}

function CustomTooltip({ active, payload }: any) {
  if (active && payload?.length && payload[0]?.payload?.entry ) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${t('wifi_dashboards.signal')}: ${payload[0].value}%`}</p>
      </div>
    )
  }
  return null;
}

export default function Graphic({events}: GraphicProps) {
  const signalStrengthLegend = t('wifi_dashboards.signal_strength')
  const ref = useRef<HTMLHeadingElement>(null)
  const [width, setWidth] = useState(0)

  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0)
  }, [ref.current])

  return (
    <div ref={ref}>
      <LineChart
        width={width}
        height={300}
        data={getData(events, signalStrengthLegend)}
        margin={{
          top: 5,
          right: 30,
          left: -20,
          bottom: 5,
        }}
        >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="name" />
        <YAxis domain={[0, 100]} />
        <Tooltip content={<CustomTooltip/>} offset={-65}/>
        <Line type="linear" dataKey={signalStrengthLegend} stroke="#e60000" dot={false} activeDot={{ r: 8 }} strokeWidth={2} />
      </LineChart>
    </div>
  )
}

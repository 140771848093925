import React, { Component } from 'react';
import Logo from 'assets/svg/logo.svg';
import apiClient from 'core/api';
import AuthService from 'core/auth';
import { Link } from 'react-router-dom';
import { ACCESS_TOKEN_KEY, VipocGroup } from 'core/auth/constants';
import { ChevronDown, User, SearchDevices } from 'assets/svg';
import TabComponent from 'core/tab/tab-container';
import { getCurrentNavbarMenu } from './redux/actions';
import { getNavbar } from './redux/reducer';
import { Navbar, NavbarState } from './redux/types';
import { TFunction } from 'i18next';
import { DecodedUser } from 'core/auth/auth-service';
import Notification from 'core/notifications';
import { navbarWithTabs } from './redux/config';
import { DeviceDetailSettingsState } from 'modules/operational/device/detail/settings/redux/types';
import UserSettings from '../../../modules/user/profile';
import { UserSettingsState } from 'modules/user/profile/redux/types';
import { envs } from 'application/envHandler';

function logout() {
  apiClient
    .get(`${envs.REACT_APP_API_URL}/auth/token-logout`, {
      params: { token: localStorage.getItem(ACCESS_TOKEN_KEY) }
    })
    .then(
      () => {
        AuthService.logoutAndRedirect()
      },
      () => {
        AuthService.logoutAndRedirect()
      }
    );
}

interface NavbarProps {
  t: TFunction;
  push: (uri: string) => void;
  changeMenu: (tab: string, id: string) => void;
  getSubMenus: (id: string) => void;
  toggleUserSettings: () => void;
  hhid?: string;
  id?: string;
  serialNr?: string;
  actions: any;
  openModal: any;
  closeModal: any;
  clear: any;
  currentPage?: string;
  settings: DeviceDetailSettingsState;
  navbar: NavbarState;
  pathname: string;
  userSettings: UserSettingsState;
  _settings: any;
  clearFields: (componentId?: string) => void;
}

export default class NavbarComponent extends Component<NavbarProps> {
  module = '';
  currentNavbar: Navbar = { available: [], current: { name: '', value: '' } };

  componentDidMount() {
    const { getSubMenus } = this.props;
    this.module = window.location.href.split('/')[4];
    const { navbar } = this.props;
    if (navbar) {
      this.currentNavbar =
        getNavbar(navbar, this.module) || navbar.navbar.entries().next().value[1];

      getSubMenus(this.module);
    }
  }

  changeOpco(opco: string) {
    AuthService.switchOpco(opco);
  }

  changeLanguage(lang: string) {
    AuthService.switchLanguage(lang);
  }

  hasNotifications(user: DecodedUser) {
    const modules = ['spectrum'];
    return user.groups.some(
      (group: VipocGroup) => group.opco === user.opco && modules.indexOf(group.moduleName) === 0
    );
  }

  onRedirect(tab: any) {
    const { currentPage, _settings } = this.props;

    if (
      this.currentNavbar &&
      getCurrentNavbarMenu(this.currentNavbar.current, currentPage) === 'settings' &&
      _settings.hasChanged
    ) {
      this.props.openModal({
        title: 'modal.title.confirm_tab_change',
        active: false,
        content: 'modal.content.confirm_tab_change',
        execute: () => {
          this.props.closeModal();
          this.props.clearFields();
          this.setRedirectUrl(tab);
        }
      });
    } else {
      this.setRedirectUrl(tab);
    }
  }

  setRedirectUrl(tab: string) {
    const { hhid, serialNr, id, push, changeMenu } = this.props;
    changeMenu(tab, this.module);

    switch (this.module) {
      case 'spectrum':
        push(`${process.env.PUBLIC_URL}/management/spectrum/${tab}`);
        break;
      case 'device':
        push(`${process.env.PUBLIC_URL}/operational/device/${id}/${tab}/${hhid}/${serialNr}`);
        break;
      default:
        return;
    }
  }

  render() {
    const { currentPage, userSettings, t, getSubMenus, toggleUserSettings } = this.props;
    const user = AuthService.getCurrentUser();
    const currentNavbarMenu = getCurrentNavbarMenu(this.currentNavbar.current, currentPage);
    const currentOpco = AuthService.getCurrentOpco();
    const currentLanguage = AuthService.getCurrentLanguage();

    const userGroupsPermissionHasActualOpco = user.groups.findIndex(
      (group) =>
        group.opco === `vf${userSettings.actualSettings ? userSettings.actualSettings.opco : ''}`
    );

    return (
      <nav className="nav">
        <div className="nav-first-level">
          <div className="nav-first-level-content">
            <p>{t('all_device_pages.opco')}</p>
            <p>{`: ${user.opco && user.opco.substr(-2).toLocaleUpperCase()}`}</p>
            <ChevronDown className="icon-svg icon-white icon-tiny has-margin-left-sm" />
            <div className="dropdown-options">
              {user.opcos &&
                user.opcos.map((opco, index) => (
                  <a
                    href="#changeopco"
                    key={index}
                    className="dropdown-item"
                    onClick={() => this.changeOpco(opco)}
                  >
                    {opco.substr(-2).toLocaleUpperCase()}
                  </a>
                ))}

              <button
                className={`button-link dropdown-link has-border-top ${
                  userSettings.actualSettings &&
                  `vf${userSettings.actualSettings.opco}` !== currentOpco &&
                  userGroupsPermissionHasActualOpco !== -1
                    ? 'button-action'
                    : ''
                }`}
                onClick={() =>
                  userSettings &&
                  userSettings.actualSettings &&
                  this.changeOpco(`vf${userSettings.actualSettings.opco}`)
                }
                disabled={
                  (userSettings.actualSettings &&
                    `vf${userSettings.actualSettings.opco}` === currentOpco) ||
                  userGroupsPermissionHasActualOpco === -1
                }
              >
                {t('general.link_restore')}
              </button>
            </div>
          </div>

          {user.lang && (
            <div className="nav-first-level-content">
              <p>{t('all_device_pages.language')}</p>
              <p>{`: ${user.lang && user.lang.substr(-2).toLocaleUpperCase()}`}</p>
              <ChevronDown className="icon-svg icon-white icon-tiny has-margin-left-sm" />

              <div className="dropdown-options">
                {user.langs &&
                  user.langs.map((lang, index) => (
                    <a
                      href="#changelanguage"
                      key={index}
                      className="dropdown-item"
                      onClick={() => this.changeLanguage(lang)}
                    >
                      {lang.substr(-2).toLocaleUpperCase()}
                    </a>
                  ))}

                <button
                  className={`button-link dropdown-link has-border-top ${
                    userSettings.actualSettings &&
                    userSettings.actualSettings.language !== currentLanguage
                      ? 'button-action'
                      : ''
                  }`}
                  onClick={() =>
                    userSettings &&
                    userSettings.actualSettings &&
                    this.changeLanguage(userSettings.actualSettings.language)
                  }
                  disabled={
                    userSettings.actualSettings &&
                    userSettings.actualSettings.language === currentLanguage
                  }
                >
                  {t('general.link_restore')}
                </button>
              </div>
            </div>
          )}

          <div className="nav-first-level-content">
            <p>{user.email}</p>
          </div>
        </div>

        <div className="nav-second-level">
          <div className="is-vertical-center is-justified-between width-full">
            <div className="navbar-icon">
              <Link to={{ pathname: '/', state: { title: 'Home' } }} href="/">
                <img src={Logo} alt="Vodafone" />
              </Link>
            </div>

            {navbarWithTabs.some((item) => this.props.pathname.includes(item)) && (
              <div
                className="navbar"
                onMouseLeave={() => getSubMenus(this.module)}
                role="navigation"
              >
                <div>
                  <TabComponent
                    onChange={(event: any) => this.onRedirect(event)}
                    tabs={this.currentNavbar.available}
                    tabSelected={currentNavbarMenu}
                    i18nkey={this.currentNavbar.i18nkey}
                    id={this.module}
                  />

                  <div
                    className={`nav-third-level ${
                      this.currentNavbar && this.currentNavbar.subtabstoshow === undefined
                        ? ''
                        : 'on-hover'
                    }`}
                  >
                    <div className="tabs is-medium">
                      <ul className="has-margin-left-none">
                        {this.currentNavbar &&
                          this.currentNavbar.subtabstoshow &&
                          this.currentNavbar.subtabstoshow.subtabs &&
                          this.currentNavbar.subtabstoshow.subtabs.map((subtab, key) => {
                            return (
                              <li
                                key={key}
                                className={currentNavbarMenu === subtab ? 'is-active' : ''}
                              >
                                <button
                                  className="button-link"
                                  onClick={() => this.onRedirect(subtab)}
                                >
                                  {t(`${this.currentNavbar.i18nkey}.menu_${subtab}`)}
                                </button>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="icons is-flex has-text-centered">
              <div
                className={
                  this.props.pathname && this.props.pathname.startsWith('/operational/device')
                    ? 'icons-with-text'
                    : ' is-hidden'
                }
              >
                <Link
                  to={{
                    pathname: '/operational/device',
                    state: { title: 'Advanced Search' }
                  }}
                  className="icon-hovered"
                >
                  <SearchDevices className="icon icon-small" />

                  <h6>{t('general.menu_devices')}</h6>
                </Link>
              </div>

              {this.hasNotifications(user) && (
                <div className="icons-with-text">
                  <Notification />
                </div>
              )}

              <div className="icons-with-text is-relative icon-hovered">
                <User className="icon icon-small" />
                <h6>{t('all_device_pages.menu_account')}</h6>

                <div className="dropdown-options">
                  <button
                    className="button-link dropdown-item"
                    onClick={() => toggleUserSettings()}
                  >
                    {t('all_device_pages.menu_settings')}
                  </button>
                  <button className="button-link dropdown-item" onClick={() => logout()}>
                    {t('general.user.logout')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserSettings />
      </nav>
    );
  }
}

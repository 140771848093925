import { add, remove } from "core/spinner/redux/actions"
import { Dispatch } from "redux"
import { DVBCHistory, DVBC_FETCH, DVBC_HISTORY_SUCCESS } from "./types"
import authService from 'core/auth'
import apiClient from "core/api"
import { addToaster } from "core/toaster/redux/actions"
import { envs } from "application/envHandler"

function fetchSuccess(payload: Array<DVBCHistory>) {
  return {
    type: DVBC_HISTORY_SUCCESS,
    payload
  }
}

export function fetchHistory(deviceId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(add(DVBC_FETCH, {}))
    await authService.refreshToken()
    try {
      const { data } = await apiClient.get(`${envs.REACT_APP_API_URL}/dvbc/device/${deviceId}`)
      dispatch(fetchSuccess(data))
    } catch (e) {
      dispatch(
        addToaster({
          title: 'rf_tuning.tab_dvbc_monitoring',
          message: 'error_messages.VIPOC_ERROR_067',
          type: 'danger'
        })
      )
    } finally {
      dispatch(remove(DVBC_FETCH))
    }
  }
}

export type PresetString = {
  label: string
  value: string
}

export type PresetNumber = {
  label: string
  value: number
}

export type ConfigString = {
  presets: Array<PresetString>
}

export type ConfigNumber = {
  presets: Array<PresetNumber>
}


export type LogsProfileConfig = {
  osProfileLevel: ConfigString
  appProfileLevel: ConfigString
  window: ConfigNumber
}

export default {
  osProfileLevel: {
    presets: [
      { label: 'settings.label_select', value: '' },
      { label: 'settings.logs_low', value: 'osProfileLow' },
      { label: 'settings.logs_mid', value: 'osProfileMid' },
      { label: 'settings.logs_high', value: 'osProfileHigh' },
    ],
  },
  appProfileLevel: {
    presets: [
      { label: 'settings.label_select', value: '' },
      { label: 'settings.logs_low', value: 'appProfileLow' },
      { label: 'settings.logs_mid', value: 'appProfileMid' },
      { label: 'settings.logs_high', value: 'appProfileHigh' },
    ],
  },
  window: {
    presets: [
      { label: '0', value: 0 },
      { label: 'settings.logs_1hour', value: 60 },
      { label: 'settings.logs_2hours', value: 120 },
      { label: 'settings.logs_8hours', value: 480 },
      { label: 'settings.logs_24hours', value: 1440 },
      { label: 'settings.logs_7days', value: 10080 },
    ],
  },
} as LogsProfileConfig

import { SortedWifiEvents } from "../../../redux/types"

export const labels = [
  '- 24h',
  '- 23h',
  '- 22h',
  '- 21h',
  '- 20h',
  '- 19h',
  '- 18h',
  '- 17h',
  '- 16h',
  '- 15h',
  '- 14h',
  '- 13h',
  '- 12h',
  '- 11h',
  '- 10h',
  '- 9h',
  '- 8h',
  '- 7h',
  '- 6h',
  '- 5h',
  '- 4h',
  '- 3h',
  '- 2h',
  '- 1h',
  '0',
]

export function getData(events: SortedWifiEvents, legend: string) {
  return labels.map(label => {
    const event = events[label]
    return {
      name: label,
      [legend]: Number(event?.signal) || 0,
      entry: event ? 1 : 0,
    }
  })
}
